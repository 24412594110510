import React, { useState, useEffect } from "react";
import { Button, Collapse, List, ListItem, ListItemText, Typography, Grid, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import _ from 'underscore';

import UserService from "../services/user.service";
import PlantsService from "../services/plants.service";
import EventBus from "../common/EventBus";
import { Modal } from 'react-bootstrap';

import almondImage from '../img/almond.png';


const examplePrompts = [
  "Find plants that have a large trunk diameter and high vigor",
  "Find plants with imageJ photos",
  "Find crops that show greenhouse apical dominance",
  "Find crops that have the highest crackout percent and have high vigor",
  "Find crops that have the highest crackout percent and have the Sf_Genotype",
  "Find crops that have the Sf_Genotype and large nuts",
  "List plants with favorable nut flavor",
  "List trees that were easy to harvest and also have high vigor",
  "List trees that were easy to harvest and also had a spreading architecture",
  "List trees that were easy to harvest, were phenotyped for architecture without weeping features",
  "List plants that don't have worm damage",
  "Find plants that have the greatest nut size and gummy nuts are low",
  "Find plants that have the smooth nuts",
  "Find plants that have the smooth nuts and large nut size",
  "Find plants that have the smooth nuts and high vigor",
  "What plants have a smooth nut set and Nonpareil as a parent",
  "What plants have a high crackout percent and Shasta as a parent",
  "Find plants that have the smooth nuts and high vigor and Shasta as a parent",
  "Find plants that have large nut size and Pyrenees as a parent",
  "Find plants that have high Nut Set and Shasta as a parent",
  "Find crops that have an doubled nuts percentage and Shasta as a parent",
  "Find plants that have Hull Split and have Shasta as a parent",
  "Find plants that have Hull Split with no separation and have Nonpareil as a parent",
  "Find plants that have hard thick shell's and smooth nuts and Shasta as a parent",
  "Find plants that don't have bud failure and Nonpareil as a parent",
  "Find plants that have a high crackout percent, no bud failure and also has Shasta as a parent",
  "Find plants that have the SF genotype and no bud failure that has a Nonpareil lineage",
  "Find plants that have easy harvesting with no bud failure and also has Shasta as a parent"
];

const phenotypes = [
    'Bloom_Time', 'Bloom_Time_Closeup_Photo', 'Bud_Failure',
    'CrackOut_KernelOnly', 'CrackOut_Percent', 'CrackOut_ShellKernel',
    'CrackOut_ShellOnly', 'Doubled_Nuts_Percent', 'Ease_of_Harvesting',
    'First_Leaf_Time', 'First_Leaf_Time_Closeup_Photo', 'Form_Geotag',
    'Greenhouse_Apical_Dominance', 'Gummy_Nuts_Percent', 'Harvest_Time',
    'Hull_Split', 'Hull_Split_Photo', 'imageJ_Kernel_Photo',
    'ImageJ_Kernel_Weight', 'imageJ_Photo', 'imageJ_Shell_Photo',
    'ImageJ_Shell_Weight', 'Kernel_Depth_Mean', 'Kernel_Depth_Measurements',
    'Kernel_Length_Mean', 'Kernel_Length_Measurements', 'Kernel_Photo',
    'Kernel_Skin_Spots', 'Kernel_Weight_Mean', 'Kernel_Weight_Measurements',
    'Kernel_Width_Mean', 'Kernel_Width_Measurements', 'Leaf_Color', 'Notes',
    'Nut_Color', 'Nut_Count', 'Nut_Flavor', 'Nut_Set', 'Nut_Shape', 'Nut_Size',
    'Nut_Type', 'Percent_Bloom', 'Percent_First_Leaf', 'Product_of_Selfing',
    'S_Locus_Genotype', 'Salt_Tolerance', 'Sf_Genotype', 'Shell_Depth_Mean',
    'Shell_Depth_Measurements', 'Shell_Length_Mean', 'Shell_Length_Measurements',
    'Shell_Photo', 'Shell_Seal', 'Shell_Thickness', 'Shell_Width_Mean',
    'Shell_Width_Measurements', 'Shriveled_Nuts_Percent', 'Smooth_vs_Wrinkled',
    'Total_InShell_Harvest_Weight', 'Tree_Architecture', 'Trunk_Diameter',
    'Vigor', 'Worm_Damage_Percent'
];

const tree_varieties = [
    'Winters', 'Wood Colony', 'Butte', 'Capitola', 'Nonpareil', 'Conway', 'Avalon', 'Monterey',
    'Sonora', 'Supareil', 'Fritz', 'Aldrich', 'Swanson', 'Price', 'Pyrenees', 'Supereil', 'Marcona',
    'Jordanolo', 'Tuono', 'Nemaguard', 'Peerless', 'Antoneta', 'Marta', 'Marley', 'Savana', 'Shasta',
    'Ruby', 'Shakh-e-bugz', '880472', 'A.D. Procopiou', 'D01-188', 'Constanti', 'Penta', 'USSR 90-09-01',
    'RHEA', 'Hann Almond', 'Carina', 'Mira', 'AN1/6', 'Yorizane', 'F8 15-25', 'Capella', 'Pioneer',
    'Abdul wahdi', 'BE-1609', 'DPRU 2327', 'Jungli Badam', 'DPRU 1161', '1-112', 'DPRU 2374', 'Titan',
    'Vela', 'Balaton', 'Profuse', 'Jungli Badam', 'Kaghzi', 'Tarragonna', 'Marinada', 'Badam', 'Vairo',
    '97,3-114', 'F8 15-38', 'Maxima', 'Independence', 'Padre', 'Neplus', 'Kapareil', 'All-In-One',
    'Texas', 'DPRU 2002 0000A'
];

const Chat = () => {
  const [content, setContent] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [data, setData] = useState([]);
  const [intersect, setIntersect] = useState([]);
  const [pedigrees, setPedigrees] = useState([]);
  const [currentImg, setCurrentImg] = useState('');
  const [redirect, doRedirect] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [showPrompts, setShowPrompts] = useState(false);
  const [showPhenotypes, setShowPhenotypes] = useState(false);
  const [showVarieties, setShowVarieties] = useState(false);
  const openaiSrc = require("../img/openai.png");
  const perplexitySrc = require("../img/perplexity.png");

  const halfLengthPrompts = Math.ceil(examplePrompts.length / 2);
  const firstHalfPrompts = examplePrompts.slice(0, halfLengthPrompts);
  const secondHalfPrompts = examplePrompts.slice(halfLengthPrompts);

  const thirdLengthPhenotypes = Math.ceil(phenotypes.length / 3);
  const firstThirdPhenotypes = phenotypes.slice(0, thirdLengthPhenotypes);
  const secondThirdPhenotypes = phenotypes.slice(thirdLengthPhenotypes, thirdLengthPhenotypes * 2);
  const thirdThirdPhenotypes = phenotypes.slice(thirdLengthPhenotypes * 2);

  const thirdLengthVarieties = Math.ceil(tree_varieties.length / 3);
  const firstThirdVarieties = tree_varieties.slice(0, thirdLengthVarieties);
  const secondThirdVarieties = tree_varieties.slice(thirdLengthVarieties, thirdLengthVarieties * 2);
  const thirdThirdVarieties = tree_varieties.slice(thirdLengthVarieties * 2);


  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleClearResponse = (e) => {
    e.preventDefault();
    setResponse('');
    setData([]);
    setIntersect([]);
    setPedigrees([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true);
    setLoadingMessage(true);
    console.log("prompt: ", prompt);

    setShowPrompts( false );
    setShowPhenotypes( false );
    setShowVarieties( false );

    PlantsService.chatWithDatabase({ prompt: prompt }).then(
      (response) => {
        console.log("chat response: ", response);
        setIntersect(response.result.intersection);
        setPedigrees(response.result.pedigrees);
        let res = _.filter(response.result.phenotypeSummary, (value) => {
          let db_rows = JSON.parse(value.databaseRows);
          value.databaseRows = db_rows;
          if (value.SelectedNumber) {
            value.descriptor = value.Threshold + " Threshold: " + value.SelectedNumber;
          }
          return value;
        });
        setData(res);
        setResponse(response.result.response);
        setSpinner(false);
        setLoadingMessage(false);
      },
      (error) => {
        console.log("error: ", error);
        setResponse('Sorry, an error occurred while fetching the response.');
        setSpinner(false);
        setLoadingMessage(false);
      }
    );
  };

  const convertToCSV = (data) => {
    if (data.length === 0) {
      return '';
    }
    const headers = Object.keys(data[0]);
    const csvRows = [headers.join(',')];

    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const downloadCSV = (data, filename) => {
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (imgUrl) => {
    console.log("imgUrl: ", imgUrl );
    let url = imgUrl.replace( '/nfs/WebserverStorage','');
    setCurrentImg(url);
    setShow(true);
  };

  const fixImageFilePath = (filePath) => {
    if (filePath) {
      let bad = "/nfs/WebserverStorage/PedigreeImages/BU/";
      let path = filePath.replace(bad, "");
      return path;
    } else {
      return filePath;
    }
  };

  const renderIntersect = () => {
    if (intersect.length > 0) {
      return (
        <div className="card mb-4">
          <div className="card-header">
            <h4>{intersect.length} plants that match your criteria.</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive" style={{ maxHeight: "500px", overflow: "auto" }}>
              <table className="table table-bordered table-striped table-hover table-sm">
                <thead className="thead-dark sticky-header">
                  <tr>
                    {Object.keys(intersect[0]).reverse().map((key, idx) => (
                      <th key={idx}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {intersect.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.entries(row).reverse().map(([key, value], valueIndex) => (
                        <td key={valueIndex} className="" style={{ maxWidth: '200px' }}>
                          {(key === 'DataFile_p1' || key === 'DataFile_p2') ? (
                            <a onClick={() => handleShow(value)} style={{ color: "blue", cursor: "pointer" }}> {fixImageFilePath(value)}</a>
                          ) : (
                            value
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <Button variant="contained" color="info" onClick={() => downloadCSV(intersect, `Intersect_Rows.csv`)}>
              Download
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="alert alert-warning" role="alert">
          No plants match your criteria.
        </div>
      );
    }
  };

  const renderTables = () => {
    if (data.length === 0) {
      return (
        <div className="alert alert-warning" role="alert">
          No data available.
        </div>
      );
    }

    return data.map((item, index) => {
      if (item.databaseRows && item.databaseRows.length > 0) {
        return (
          <div key={index} className="card mb-4">
            <div className="card-header">
              <h4>{item.databaseRows.length} Rows, <span style={{ fontSize: "16px" }}>{item.phenotype}: {item.descriptor}</span></h4>
            </div>
            <div className="card-body">
              <div className="table-responsive" style={{ maxHeight: "500px", overflow: "auto" }}>
                <table className="table table-bordered table-striped table-hover table-sm">
                  <thead className="thead-dark sticky-header">
                    <tr>
                      {Object.keys(item.databaseRows[0]).reverse().map((key, idx) => (
                        <th key={idx}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.databaseRows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).reverse().map(([key, value], valueIndex) => (
                          <td key={valueIndex} style={{ maxWidth: '200px' }}>
                            {key === 'DataFile' ? (
                              <a onClick={() => handleShow(value)} style={{ color: "blue", cursor: "pointer" }}>
                                {fixImageFilePath(value)}
                              </a>
                            ) : (
                              value
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <Button variant="contained" color="info" onClick={() => downloadCSV(item.databaseRows, `${item.phenotype}_${item.descriptor}.csv`)}>
                Download
              </Button>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }).filter(component => component !== null);
  };

  const renderPedigrees = () => {
    if (pedigrees.length > 0) {
      return (
        <div className="card mb-4">
          <div className="card-header">
            <h4> {pedigrees[0].Variety} Database Rows:{pedigrees.length} </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive" style={{ maxHeight: "500px", overflow: "auto" }}>
              <table className="table table-bordered table-striped table-hover table-sm">
                <thead className="thead-dark sticky-header">
                  <tr>
                    {Object.keys(pedigrees[0] || {}).reverse().map((key, idx) => (
                      <th key={idx}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pedigrees.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.entries(row).reverse().map(([key, value], valueIndex) => (
                        <td key={valueIndex} style={{ maxWidth: '200px' }}>
                          {key === 'DataFile' ? (
                            <a onClick={() => handleShow(value)} style={{ color: "blue", cursor: "pointer" }}>
                              {fixImageFilePath(value)}
                            </a>
                          ) : (
                            value
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer">
            <Button variant="contained" color="info" onClick={() => downloadCSV(pedigrees, `Intersect_Rows.csv`)}>
              Download
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="alert alert-warning" role="alert">
          No pedigree's match your criteria.
        </div>
      );
    }
  };

  useEffect(() => {
    // Get Conten
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
    // Get User
    UserService.getUserBoard().then(
      (response) => {
        console.log("user response: ", response);
      },
      (error) => {
        console.log("user error: ", error );
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error: ", _content);
        doRedirect(true);
        // Logout
        EventBus.dispatch("logout");

      }
    );
  }, []);

  return (
    <>
      {spinner && (
        <div className="almond-spinner">
          <img src={almondImage} alt="Almond Spinner" />
        </div>
      )}
      <div className="container">
        <header className="jumbotron">
          <div className="float-right">
            <img src={openaiSrc.default} alt="openaia-logo" height={50} style={{ marginRight: "5px" }} />
            <br />
            <img src={perplexitySrc.default} alt="p-logo" height={40} style={{ marginRight: "5px" }} />
          </div>
          <h3>Phenotype GPT</h3>
          {showAlert ? (

            <Alert
              severity="info"
              style={{ whiteSpace: 'pre-wrap', position: 'relative' }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowAlert(false)}
                  style={{ position: 'absolute', top: '5px', right: '5px' }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Welcome to our AI-powered Phenotype Database Chatbot! This innovative application allows you to explore a vast collection of phenotypes and pedigrees using natural language prompts.
              <br /><br />
              To get the most accurate and relevant results, we recommend focusing your queries on one or two specific phenotypes along with a single pedigree. Grouping multiple pedigrees or asking for date ranges may yield less precise information as our AI continues to grow and improve.
              <br /><br />
              Our chatbot utilizes advanced natural language processing technology to understand your queries and retrieve the most pertinent phenotypic data from our extensive database. Feel free to ask about any phenotypes or pedigrees you're interested in, and our AI will provide you with detailed information.
              <br /><br />
              We're constantly working to enhance our AI's capabilities, so if you have any feedback or suggestions, please let us know. Explore our Phenotype Database Chatbot and unlock a wealth of knowledge at your fingertips!
            </Alert>
          ) : (
            <>
              <Button variant="contained" color="info" size="small" onClick={() => setShowAlert(true)}>
                Show Info
              </Button>
              <br />
            </>
          )}
          <small style={{ fontSize: "12px" }}>
            built with <a target="_blank" rel="noopener noreferrer" href="https://openai.com/">openai</a> and <a target="_blank" rel="noopener noreferrer" href="https://perplexity.com/">perplexity.ai</a>
          </small>
        </header>

        <div className="jumbotron" id="chat-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="userPrompt" className="form-label">Enter your prompt:</label>
              <div className="input-group">
                <input type="text" className="form-control" id="userPrompt" value={prompt} onChange={handlePromptChange} placeholder="Type your prompt here" />
                <Button type="submit" variant="contained" color="primary">Submit</Button>
                {response.length > 0 && (
                  <Button variant="contained" color="error" onClick={handleClearResponse}>Clear</Button>
                )}
              </div>
            </div>
          </form>

          <Grid container spacing={2} alignItems="center" style={{ marginBottom: "15px" }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowPrompts(!showPrompts)}
              >
                {showPrompts ? 'Hide Example Prompts' : 'Show Example Prompts'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowPhenotypes(!showPhenotypes)}
              >
                {showPhenotypes ? 'Hide Phenotypes' : 'Show Phenotypes'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowVarieties(!showVarieties)}
              >
                {showVarieties ? 'Hide Varieties' : 'Show Varieties'}
              </Button>
            </Grid>
          </Grid>

          <Collapse in={showPrompts}>
            <div style={{ display: 'flex' }}>
              <List style={{ flex: 1 }}>
                {firstHalfPrompts.map((prompt, index) => (
                  <ListItem key={index} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={prompt} />
                  </ListItem>
                ))}
              </List>
              <List style={{ flex: 1 }}>
                {secondHalfPrompts.map((prompt, index) => (
                  <ListItem key={index + halfLengthPrompts} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={prompt} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Collapse>

          <Collapse in={showPhenotypes}>
            <div style={{ display: 'flex' }}>
              <List style={{ flex: 1 }}>
                {firstThirdPhenotypes.map((phenotype, index) => (
                  <ListItem key={index} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={phenotype} />
                  </ListItem>
                ))}
              </List>
              <List style={{ flex: 1 }}>
                {secondThirdPhenotypes.map((phenotype, index) => (
                  <ListItem key={index + thirdLengthPhenotypes} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={phenotype} />
                  </ListItem>
                ))}
              </List>
              <List style={{ flex: 1 }}>
                {thirdThirdPhenotypes.map((phenotype, index) => (
                  <ListItem key={index + thirdLengthPhenotypes * 2} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={phenotype} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Collapse>

          <Collapse in={showVarieties}>
            <div style={{ display: 'flex' }}>
              <List style={{ flex: 1 }}>
                {firstThirdVarieties.map((variety, index) => (
                  <ListItem key={index} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={variety} />
                  </ListItem>
                ))}
              </List>
              <List style={{ flex: 1 }}>
                {secondThirdVarieties.map((variety, index) => (
                  <ListItem key={index + thirdLengthVarieties} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={variety} />
                  </ListItem>
                ))}
              </List>
              <List style={{ flex: 1 }}>
                {thirdThirdVarieties.map((variety, index) => (
                  <ListItem key={index + thirdLengthVarieties * 2} style={{ padding: "0px 10px" }}>
                    <ListItemText primary={variety} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Collapse>


          {response && (
            <>
              <div className="card mb-4">
                <div className="card-body">
                  <Typography variant="h5" className="card-title">AI Response</Typography>
                  <Typography className="card-text mb-3" style={{ whiteSpace: 'pre-wrap', marginBottom: "10px" }}>
                    {response}
                  </Typography>
                </div>
              </div>

              {renderIntersect()}
              {renderTables()}
              {renderPedigrees()}
            </>
          )}
        </div>
        {loadingMessage && (
          <div className="alert alert-warning" role="alert">
            <strong>Please wait:</strong> We are processing your request with our server. This may take a moment. Thank you for your patience.
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Image Preview</Modal.Title>
          <button type="button" className="close" aria-label="Close" style={{ cursor: "pointer" }} onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <img src={currentImg} alt="Preview" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;
