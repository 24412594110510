import React, { useState, useEffect } from "react";
import _ from "underscore";

import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { Table, Pagination } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination from '@mui/material/TablePagination';
import {  Typography, Grid, Paper, CircularProgress, Alert } from '@mui/material';

import GroupsService from "../services/groups.service";
import SearchPlants from "./SearchPlants";
import SearchPhenotype from "./SearchPhenotype";

// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
import { Redirect } from "react-router-dom";
const ENDPOINT = socketUrl();
console.log("endpoint: ", ENDPOINT );

const AddRemoveModal = (props) => {
    // console.log("props: ", props);
    let {
        show,
        setShow,
        groups,
        setSpinner,
        currentUser,
    } = props;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let [group, setGroup ] = useState("");
    let [loading, setLoading] = useState(false);
    let [loadingSearch, setLoadingSearch ] = useState(false);
    let [plants, setPlants] = useState([]);
    let [selectedPlants, setSelectedPlants] = useState([]);
    let [searchResults, setSearchResults] = useState([]);
    let [clones, setClones ] = useState([]);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loadingMessage, setLoadingMessage ] = useState('');
    const [phenotypeMessage, setPhenotypeMessage ] = useState(false);
    // State to manage pagination
    // Pagination for the plants table
    const [plantsPage, setPlantsPage] = useState(0);
    const [plantsRowsPerPage, setPlantsRowsPerPage] = useState(10);

    // Pagination for the search results table
    const [searchResultsPage, setSearchResultsPage] = useState(0);
    const [searchResultsRowsPerPage, setSearchResultsRowsPerPage] = useState(10);

    // Handling change of page for plants table
    const handlePlantsChangePage = (event, newPage) => {
        setPlantsPage(newPage);
    };

    // Handling change in rows per page for plants table
    const handlePlantsChangeRowsPerPage = (event) => {
        setPlantsRowsPerPage(parseInt(event.target.value, 10));
        setPlantsPage(0); // Reset page to 0 on rows per page change
    };

    // Handling change of page for search results table
    const handleSearchResultsChangePage = (event, newPage) => {
        setSearchResultsPage(newPage);
    };

    // Handling change in rows per page for search results table
    const handleSearchResultsChangeRowsPerPage = (event) => {
        setSearchResultsRowsPerPage(parseInt(event.target.value, 10));
        setSearchResultsPage(0); // Reset page to 0 on rows per page change
    };

    // Calculate the current data slice for plants table
    const currentPlantsPageData = plants.slice(plantsPage * plantsRowsPerPage, plantsPage * plantsRowsPerPage + plantsRowsPerPage);

    // Calculate the current data slice for search results table
    const currentSearchResultsPageData = searchResults.slice(searchResultsPage * searchResultsRowsPerPage, searchResultsPage * searchResultsRowsPerPage + searchResultsRowsPerPage);


    const handleGroupChange = (event) => {
        let group = event.target.value;
        console.log("group: ", group);
        setPlants([]);
        setGroup( group );
        setLoading(true);
    
       // Send Request 
        GroupsService.searchGroups(group).then(
            (response) => {
                console.log("search group response: ", response);
                if (response.status === 0) {
                    setLoading(false)
                    setPlants( response.result );

                } else {
                    console.log('error: ', response.message);
                    setLoading(false)
                }
            },
            (error) => {
                console.log('error: ', error);
                setLoading(false)
            }
        )
    };

    const handlePlantSelection = (idPlant) => {
        if (selectedPlants.includes(idPlant)) {
            setSelectedPlants(selectedPlants.filter(plantId => plantId !== idPlant));
        } else {
            setSelectedPlants([...selectedPlants, idPlant]);
        }
    };

    const togglePlantSelection = (plantId) => {
        console.log("plantId: ", plantId );
        console.log("searchResults: ", searchResults );
        const selectedPlant = searchResults.find(p => p.idPlant.toString() == plantId.toString());
        if (selectedPlant) {
            // Check if the plant is already in the array
            setPlants(currentPlants => {
                const isDuplicate = currentPlants.some(p => p.idPlant.toString() == plantId.toString());
                if (isDuplicate) {
                    // Notify the user about the duplicate
                    alert('This plant has already been added.');
                    return currentPlants; // Return the existing array without adding the duplicate
                } else {
                    // Add to plants array at the start if selected from searchResults
                    // Remove from searchResults
                    setSearchResults(currentSearchResultsPageData => currentSearchResultsPageData.filter(p => p.idPlant !== plantId));
                    return [selectedPlant, ...currentPlants];
                }
            });
        } else {
            // Existing functionality to handle unselecting or toggling from the plants list
            setSelectedPlants(currentSelectedPlants => 
                currentSelectedPlants.includes(plantId) ? 
                currentSelectedPlants.filter(id => id !== plantId) : 
                [...currentSelectedPlants, plantId]);
        }
    };

    const deleteSelectedPlants = () => {
        const newPlants = plants.filter(plant => !selectedPlants.includes(plant.idPlant));
        setPlants(newPlants);
        setSelectedPlants([]);
    };

    const isAllSelected = () => {
        const currentPagePlantIDs = currentPlantsPageData.map(plant => plant.idPlant);
        return currentPagePlantIDs.every(id => selectedPlants.includes(id));
    };

    const toggleSelectAllPlants = () => {
        console.log("Toggling select/deselect all plants from the current page.");
        
        const currentPagePlantIDs = currentPlantsPageData.map(plant => plant.idPlant);
    
        if (isAllSelected()) {
            // Deselect all if currently all are selected
            const newSelectedPlants = selectedPlants.filter(id => !currentPagePlantIDs.includes(id));
            setSelectedPlants(newSelectedPlants);
        } else {
            // Select all unique plants that are not currently selected
            let duplicatesCount = 0;
            const allUniquePlantIDsToSelect = currentPagePlantIDs.filter(id => {
                if (selectedPlants.includes(id)) {
                    duplicatesCount++;
                    return false;
                }
                return true;
            });
            if (duplicatesCount > 0) {
                alert(`${duplicatesCount} duplicate plant(s) not added.`);
            }
            setSelectedPlants([...selectedPlants, ...allUniquePlantIDsToSelect]);
        }
    };

    const selectAllPlantsAvoidDuplicates = () => {
        console.log("Selecting all non-duplicate plants.");
    
        let duplicateCount = 0;
        const newPlants = [];
        const remainingSearchResults = [];
    
        // Process each plant in search results
        searchResults.forEach(plant => {
            if (plants.some(p => p.idPlant.toString() === plant.idPlant.toString())) {
                // Increment duplicate counter if plant is already in plants array
                duplicateCount++;
                remainingSearchResults.push(plant);
            } else {
                // Add plant to newPlants if not a duplicate
                newPlants.push(plant);
            }
        });
    
        // Notify user about duplicates, if any
        if (duplicateCount > 0) {
            alert(`${duplicateCount} duplicate plant(s) were not moved.`);
        }
    
        // Update states accordingly
        setPlants(prevPlants => [...newPlants, ...prevPlants]);
        setSearchResults(remainingSearchResults);
    };

    const hanldeUpdateGroup = () => {
        let obj = {
            group: group,
            plants: plants,
        };
        console.log("obj: ", obj );
        // Send Request
        GroupsService.updatePlantGroup(obj)
            .then(response => {
                console.log("update response: ", response);
                setMessage('Group updated successfully!');
                setIsError(false);
                setTimeout( ()=> {
                    setMessage('');
                }, 3000);
            })
            .catch(error => {
                console.log("error: ", error);
                setMessage('Failed to update group. Please try again.');
                setIsError(true);
                setTimeout( ()=> {
                    setIsError( false );
                    setMessage('');
                }, 3000);
            });

    }

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        console.log("socket: ", socket);
        
        /** Search Results */
        socket.on("pedigree-search", response => {
            console.log("socket.io results: ", response);
            if( currentUser.id === response.user.id ) {
                if (response.status === 0) {
                    // setLoadingMessage("");
                    var results = [];
                    _.each(response.result, function (value) {
                        // console.log("value: ", value );
                        var obj = {
                            idPlant: value.idPlant,
                            PlantCode: value.PlantCode,
                            AlternatePlantID: value.AlternatePlantID,
                            'Verinomics Code': value.PlantCode,
                            'Company Code': value.AlternatePlantID,
                            CrossCode: value.CrossCode,
                            AlternateCrossID: value.AlternateCrossID,
                            ExternalID: value.AlternatePlantID,
                        };
                        results.push(obj);
                    });
    
                    setSearchResults(results);
                    setLoadingSearch(false);
                    setPhenotypeMessage(false);
                    // setCommand(response.cmd);
                } else if (response.status === 2) {
                    console.warn("no plants...");
                    // No Plants Found 
                    setLoadingMessage("No plants found in the database.");
                    setTimeout(function () {
                        setLoadingMessage("");
                        setLoadingSearch(false);
                    }, 3000);
                } else {
                //    setLoadingMessage("");
                   console.warn("something went wrong");
                   setLoading(false)
                }
            } 
        });

        socket.on("clones", response => {
            // console.log("socket.io results: ", response);
            if( currentUser.id === response.user.id ) {
                setClones( response.result );
                // setShowClonesModal( true );
                setLoading(false);
            }
        });

    }, []);

    return (
        <>
        <Modal show={show} onHide={handleClose} className="custom-modal">
                <Modal.Header>
                    <Modal.Title>Add/Remove from Groups</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer"}}/>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <div className="column">
                        <Paper elevation={3} sx={{ p: 4, marginBottom: "15px", flexGrow: 1 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>Step 1: Select a Group</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Select Group</FormLabel>
                                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="select-groups-label">Groups</InputLabel>
                                        <Select
                                            labelId="select-groups-label"
                                            id="select-groups"
                                            label="Groups"
                                            value={group}
                                            onChange={handleGroupChange}
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {groups.map((group, index) => (
                                                <MenuItem key={index} value={group}>
                                                    {group.Name} - {group.Description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {loading && (
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Grid>
                                )}
                                {/* {loading && (
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                )} */}
                            </Grid>
                        </Paper>
                        {/* Table */}
                        {plants.length > 0 && (
                            <>
                                <Button variant="contained" className="float-right" onClick={deleteSelectedPlants} disabled={selectedPlants.length === 0}>
                                    Delete Selected
                                </Button>
                                <Button
                                    variant="outlined"
                                    className="float-right"
                                    onClick={toggleSelectAllPlants}
                                >
                                    {isAllSelected() ? 'Deselect All' : 'Select All'}
                                </Button>

                                <h5>{group.Name} with { plants.length } plants </h5>
                                <Table striped hover responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Verinomics Code</th>
                                            <th>External ID</th>
                                            <th>Variety</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPlantsPageData.map(plant => (
                                            <tr key={plant.idPlant}>
                                                <td className="center" style={{padding: "0px", paddingTop: "10px"}}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedPlants.includes(plant.idPlant)}
                                                                onChange={() => handlePlantSelection(plant.idPlant)}
                                                                color="primary" // You can choose other colors like 'secondary'
                                                            />
                                                        }
                                                        label="" // Optional label
                                                    />
                                                </td>
                                                <td style={{padding: "15px"}}>{plant.PlantCode}</td>
                                                <td style={{padding: "15px"}}>{plant.ExternalID}</td>
                                                <td style={{padding: "15px"}}>{plant.Variety}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {/* Pagination */}
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={plants.length}
                                    rowsPerPage={plantsRowsPerPage}
                                    page={plantsPage}
                                    onPageChange={handlePlantsChangePage}
                                    onRowsPerPageChange={handlePlantsChangeRowsPerPage}
                                />

                            </>
                        )}
                    </div>

                    <div className="column">
                        <SearchPlants 
                            setSpinner={setLoading}
                            loading={loadingSearch}
                            setLoading={setLoadingSearch}
                            setSearchResults={setSearchResults}
                            phenotypeMessage={phenotypeMessage}
                            setPhenotypeMessage={setPhenotypeMessage}
                        />

                        {/* <SearchPhenotype
                            setSpinner={setSpinner}
                            setLoading={setLoadingSearch}
                            setSearchResults={setSearchResults}
                        /> */}
                   
                        { loadingMessage !== "" && (
                            <p>No plants found</p>
                        )}
                        { searchResults.length > 0 && (
                            <>
                                <Table striped hover responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Verinomics Code</th>
                                            <th>External ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentSearchResultsPageData.map(plant => (
                                            <tr key={plant.idPlant}>
                                                <td className="center" style={{ padding: "0px", paddingTop: "10px" }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedPlants.includes(plant.idPlant)}
                                                                onChange={() => togglePlantSelection(plant.idPlant)}
                                                                color="primary" // You can choose other colors like 'secondary'
                                                            />
                                                            // This is what we want to change!! 
                                                            // We want to move the selected row into the plants array, at the top of the array too
                                                        }
                                                        label="" // Optional label
                                                    />
                                                </td>
                                                <td style={{ padding: "15px" }}>{plant.PlantCode}</td>
                                                <td style={{ padding: "15px" }}>{plant.AlternatePlantID}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {/* Pagination */}
                                <Button variant="outlined" className="float-left" onClick={selectAllPlantsAvoidDuplicates}>
                                    Select All
                                </Button>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={searchResults.length}
                                    rowsPerPage={searchResultsRowsPerPage}
                                    page={searchResultsPage}
                                    onPageChange={handleSearchResultsChangePage}
                                    onRowsPerPageChange={handleSearchResultsChangeRowsPerPage}
                                />
                            </>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {message && (
                        <Alert severity={isError ? 'error' : 'success'} style={{ marginTop: '20px', textAlign: 'center' }}>
                            {message}
                        </Alert>
                    )}
                    <Button variant="contained" onClick={hanldeUpdateGroup} style={{ marginLeft: "5px"}} disabled={plants.length === 0}>
                        Update Group
                    </Button>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddRemoveModal;







