import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EventBus from "../common/EventBus";
import { Avatar, Box, Button, CircularProgress, Container, Grid, TextField, Typography, Alert, IconButton, InputAdornment } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import { Visibility, VisibilityOff } from "@mui/icons-material";

import userService from "../services/user.service";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState(null);
  const [redirect, doRedirect] = useState(false)

  const profile_keys = ["email"];

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handlePasswordChange = () => {
    setLoading(true);

    if (!currentPassword.trim() || !newPassword.trim()) {
      alert("Both current and new passwords are required.");
      setLoading(false);
      return;
    }

    if (currentPassword === newPassword) {
      alert("New password cannot be the same as the current password.");
      setLoading(false);
      return;
    }

    if (newPassword.length < 8) {
      alert("New password must be at least 8 characters long.");
      setLoading(false);
      return;
    }

    var obj = {
      user: currentUser,
      oldPassword: currentPassword,
      newPassword: newPassword
    };

    // Send Request
    userService.changePassword(obj).then(
      (resposne) => {
        console.log("password response: ", resposne);
        setNotification('Password changed successfully');
        setLoading(false);
        setCurrentPassword("");
        setNewPassword("");
        setTimeout(() => {
          setNotification('');
        }, 3000);

      },
      (error) => {
        console.log('password error: ', error);
        setNotification('Something went wrong, try again.');
        setLoading(false);
        setTimeout(() => {
          setNotification('');
        }, 3000);
      }
    )

  };

  const handleFeedbackSubmit = () => {
    setLoading(true);

    if (!feedback.trim()) {
      setFeedbackStatus('Please provide feedback before submitting.');
      setLoading(false);
      return;
    }

    let obj = {
      user: currentUser,
      feedback: feedback,
    };
    console.log("obj: ", obj );
    // Send Request
    userService.feedback(obj).then(
      (response) => {
        console.log("feedback response: ", response);
        setFeedbackStatus('Thank you for your feedback! We will get back to you shortly.');
        setLoading(false);
        setFeedback('');
        setTimeout(() => {
          setFeedbackStatus(null);
        }, 3000);
      },
      (error) => {
        console.log('feedback error: ', error);
        setFeedbackStatus('Something went wrong, contact Evan Buss, evan@verinomics.com.');
        setLoading(false);
        setFeedback('');
        setTimeout(() => {
          setFeedbackStatus(null);
        }, 5000);
      }
    )
  
     
  };

  useEffect(() => {
    // Check User
    userService.getUserBoard().then(
        (response) => {
            // console.log("user response: ", response);
        },
        (error) => {
            const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            console.log("error: ", _content);
            doRedirect( true );
            
            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
        }
    );

}, [currentUser]);

  return (
    <Container maxWidth="md">
      <Box
        bgcolor="white"
        p={4}
        borderRadius={2}
        boxShadow={2}
        mt={4}
      >
        {loading && <CircularProgress />}

        <Box display="flex" alignItems="center" mb={4}>
          <Avatar src={currentUser.gravatar} alt="User Gravatar" sx={{ width: 56, height: 56, mr: 2 }} />
          <Typography variant="h5">{currentUser.username}'s Profile</Typography>
        </Box>

        <Box mt={4} borderTop={1} pt={4}>
          <Typography variant="h6" mb={2}>Change Password</Typography>

          <TextField
            label="Current Password"
            type={showCurrentPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            margin="normal"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            fullWidth
            variant="outlined"
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordChange}
            sx={{ mt: 2 }}
          >
            Update Password
          </Button>
          
          {notification && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {notification}
            </Alert>
          )}

          <Box mt={4} borderTop={1} pt={4}>
            <Typography variant="h6" mb={2}>Send Feedback</Typography>

            <Alert severity="info" sx={{ mb: 2 }}>
              Please provide your feedback. Our team at Verinomics will review it and get back to you shortly.
            </Alert>

            <TextField
              label="Your Feedback"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              margin="normal"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleFeedbackSubmit}
              sx={{ mt: 2 }}
            >
              <SendIcon style={{marginRight: "8px"}}/>
              Send Feedback
            </Button>

            {feedbackStatus && (
              <Alert severity={feedbackStatus.includes('Thank you') ? "success" : "error"} sx={{ mt: 2 }}>
                {feedbackStatus}
              </Alert>
            )}
          </Box>

         
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
