import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import _ from "underscore";

import PlantsService from "../services/plants.service";

// XLSX
import * as XLSX from 'xlsx/xlsx.mjs';

const ClonesModal = (props) => {
    // console.log("props: ", props);
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);

    let results = props.clones;

    const column = results.length === 0 ? [] : Object.keys(results[0]) ;
    // console.log("column: ", column );

    // get table heading data
    let ThData = () => {
        return column.map((data) => {
            if( data !== "idPlant") {
                return <th key={data}>{data}</th>
            } else {
                return null;
            }
        })
    }

    // get table row data
    let tdData = () => {
        return results.map((data, index) => {
            return (
                <tr key={index}>
                    {
                        column.map((v, i) => {
                            if( v !== "idPlant") {
                                if( v === "PlantCode" ) {
                                    return <td key={i}> <p value={data[v]} style={{color: "#1a0dab", cursor: "pointer"}} onClick={ handleGetInfo }> {data[v]} </p> </td>
                                } else {
                                    return <td key={i}>{data[v]}</td>
                                }
                            } else {
                                return null;
                            }
                        })
                    }
                </tr>
            )
        })
    }

    function handleGetInfo( event ) {
        handleClose();
        props.setSpinner(true)
        console.log('clicked: ', event.target.innerText.trim() );
        var obj = {
            code: event.target.innerText.trim(),
            company: "Burchell"
        }
        console.log("obj: ", obj);
        PlantsService.getPlantSummary(obj).then(
            (response) => {
                console.log("plant response: ", response);

                var arr = [];
                _.each(Object.keys(response.result), function (value) {
                    if (typeof response.result[value] == "string") {
                        var obj = {
                            key: value,
                            data: response.result[value]
                        }
                        arr.push(obj);
                    }
                });
                // console.log("arr: ", arr);
                props.setPlantPhenotypes( response.result.Phenotypes );
                props.setPlantDescriptors( response.result.Descriptors );
                props.setPlantSummary(arr);
                props.setShowSummaryModal(true);
                props.setSpinner(false)
            },
            (error) => {
                console.log("error: ", error);
                props.setSpinner(false)
            }
        );
    }

    let downloadClones = () => {
        props.setSpinner(true)
        console.log("download clones.");

        let results = props.clones;

        /* generate a worksheet */
        var ws = XLSX.utils.json_to_sheet( results );

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, "Verinomics");

        /* write workbook and force a download */
        XLSX.writeFile( wb, "ClonesDownload.xlsx");
        // Loading
        setTimeout(function() {
            props.setSpinner(false)
        },500);
    }

    return (
        <>
            <Modal size="lg" show={props.show} onHide={handleClose} dialogClassName="modal-width">
                <Modal.Header>
                    <Modal.Title>Clones</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer"}}/>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12" style={{ overflow: "auto" }}>

                        <table className="table table-striped">
                            <thead>
                                <tr>{ThData()}</tr>
                            </thead>
                            <tbody>
                                {tdData()}
                            </tbody>
                        </table>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={downloadClones} startIcon={<DownloadIcon />}>
                        Download Clones
                    </Button>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ClonesModal;







