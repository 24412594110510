import * as React from 'react';
import Box from '@mui/material/Box';

export default function SimpleBottomNavigation() {
  let year = new Date().getFullYear();

  return (
    <>
        <Box className='footer' sx={{ width: "100%", color: "white" }} >
            <div className='text-center' style={{ marginTop: "10px" }}>
                <p style={{color: "grey"}}>&copy; Copyright {year}, Verinomics inc</p>
            </div>
        </Box>
    </>
  );
}
