// http.js
const getBaseUrl = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            // url = 'http://192.168.12.159:8080/api/test/';
            // url = 'http://localhost:8080/api/test/';
            url = 'https://www.burchell.verinomics.com/api/test/';
            // url = 'https://50.230.108.188:8080/api/test/';
            break;
        default:
            // url = 'http://192.168.12.234:8080/api/test/';
            url = 'http://localhost:8080/api/test/';
    }
    return url;
}

export default getBaseUrl;