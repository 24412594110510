import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

function DatePicker(props) {
    const [ value, setValue ] = useState( props.date );

    const handleChange = ( newValue ) => {
        console.log("newValue: ", newValue );
        setValue(newValue);
        props.setDate( newValue );
    };

  return (
    <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    label={ props.display }
                    inputFormat="MM/dd/yyyy"
                    value={ value }
                    onChange={ handleChange }
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    </>
  );
}

export default DatePicker;

