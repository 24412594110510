import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

const user = JSON.parse(localStorage.getItem("user"));
// console.log("user: ", user );

const getPlants = () => {
    return axios.get(API_URL + "plants", { headers: authHeader() });
  }; 
  
const getCrosses = () => {
    return axios.get(API_URL + "crosses", { headers: authHeader() });
}; 

const getTags = () => {
    return axios.get(API_URL + "tags", { headers: authHeader() });
}; 

const getVarieties = () => {
    return axios.get(API_URL + "varieties", { headers: authHeader() });
}; 

const searchPlants = (plant) => {
   // console.log("from service: ", plant );
  return axios
      .post(API_URL + "search", { plant, user },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const searchCrosses = (plant) => {
    // console.log("from service: ", plant );
    return axios
        .post(API_URL + "searchCrosses", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
  };

const getPlantSummary = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "getPlantSummary", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const searchTags = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "tags", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const searchVarieties = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "searchVarieties", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const findLocations = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "locations", { plant },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const searchBarcode = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "barcode", { plant },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const addNotes = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "notes", { plant },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const findClones = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "clones", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const pedigreeSearchTerms = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "pedigreeSearchTerms", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const chatWithDatabase = (plant) => {
    // console.log("from service: ", plant);
    return axios
        .post(API_URL + "chatWithDatabase", { plant, user },{ headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};


const PlantsService = {
    getPlants,
    getCrosses,
    getTags,
    searchPlants,
    searchCrosses,
    getPlantSummary,
    searchTags,
    getVarieties,
    searchVarieties,
    findLocations,
    searchBarcode,
    addNotes,
    findClones,
    pedigreeSearchTerms,
    chatWithDatabase,
};

export default PlantsService;
