import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;


const getPlantGroups = () => {
  return axios.get(API_URL + "groups", { headers: authHeader() });
};

const getPhenotypes = () => {
  return axios.get(API_URL + "phenotypes", { headers: authHeader() });
};

const searchGroups = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "searchGroups", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const removePlantFromGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "remove", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const getAvailablePhenotypes = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "phenotypes", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const searchPhenotypes = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "searchPhenotypes", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const addAllPlantsToGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "addAllPlantsToGroup", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const addPlantToGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "addPlant", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const getPhenotypeSearchType = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "phenotypeSearchType", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const createGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "createGroup", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const mergeGroups = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "mergeGroups", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const updatePlantGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "updatePlantGroup", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const createMergedGroup = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "createMergedGroup", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const updateMergedGroups = (group) => {
  // console.log("from service: ", group );
  return axios
      .post(API_URL + "updateMergedGroups", { group },{ headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const GroupService = {
  getPlantGroups,
  getPhenotypes,
  searchGroups,
  removePlantFromGroup,
  getAvailablePhenotypes,
  searchPhenotypes,
  addAllPlantsToGroup,
  addPlantToGroup,
  getPhenotypeSearchType,
  createGroup,
  mergeGroups,
  updatePlantGroup,
  createMergedGroup,
  updateMergedGroups,
};

export default GroupService;