import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';

import _ from 'underscore';
import GroupService from "../services/groups.service";

const PhenotypeModal = (props) => {
    // console.log("props: ", props);
    // const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([]);
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);

    const handleChange = (event) => {
        const value = event.target.value;
        console.log("value: ", value );
        if (value === "Check All") {
            setSelected(selected.length === props.phenotypes.length ? [] : _.pluck(props.phenotypes, "value"));
            return;
        }
        // added below code to update selected options
        const list = [...selected];
        const index = list.indexOf(value);
        index === -1 ? list.push(value) : list.splice(index, 1);
        console.log("list: ", list );
        setSelected(list);
    };

    const searchPhenotypes = () => {
        props.setSpinner( true );
        handleClose();
        console.log("selected: ", selected );
        var ids = _.pluck( props.searchResults, 'idPlant');
        if( selected.includes("Check All") ) {
            // checked.shift();
            selected.splice(selected.indexOf("Check All"), 1); 
        } 
        var obj = {
            idCompany: 1,
            company: "Burchell",
            phenotypes: selected.join(','),
            ids: ids.join(",")
        };
        console.log("obj: ", obj );
        // Send Request
        GroupService.searchPhenotypes( obj ).then(
            (response) => {
                console.log("search response: ", response );

                props.setSpinner( false );
                props.setSelection('')
                props.setPhenotypeResults( response.result )
                props.setShowPhenotypes(true)
                handleClose();
            }, 
            (error) => {
                console.log("error: ", error );
                props.setSpinner( false );
            }
        )

    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Available Phenotypes</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        { props.phenotypes.map((value) => {
                            const labelId = `checkbox-list-label-${value.value}`;

                            return (
                                <ListItem key={value.value} >
                                    <ListItemButton role={undefined} onChange={ handleChange } dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                value={ value.value }
                                                checked={ selected.includes( value.value ) }
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={`${value.value}`} />
                                    </ListItemButton>
                                </ListItem>
                            );

                        })}
                    </List>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={searchPhenotypes} startIcon={<SearchIcon />}>
                        Search Phenotypes
                    </Button>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PhenotypeModal;
