import React, { useState, useEffect, useRef } from "react";
// import { TextField, Autocomplete, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { TextField, Autocomplete, IconButton, Button, Alert, Card, Paper, Box, CardHeader, CardContent, CardActions, FormControl, InputLabel, Select, MenuItem, Typography, Grid, InputAdornment, CircularProgress } from '@mui/material';

import PlantsService from "../services/plants.service";
import GroupService from "../services/groups.service";
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from "./DatePicker"; // Assuming this is a suitable date picker component
import _ from 'underscore';
import { DateTime } from "luxon";

import { FixedSizeList } from 'react-window';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';

const searchOptions = [
    { label: "Plant Search" },
    { label: "Parent Search" },
    { label: "Cross Search" },
    { label: "Variety Search" },
    { label: "Tag Search" },
];

const SearchPlants = (props) => {
    let { 
        setSpinner,
        loading,
        setLoading,
        setSearchResults,
        phenotypeMessage,
        setPhenotypeMessage
    } = props;
    const [plants, setPlants] = useState([]);
    const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0]);
    // const [loading, setLoading] = useState(false);

    const [phenotypes, setPhenotypes] = useState([]);
    const [currentPhenotype, setCurrentPhenotype] = useState(null);
    const [valueHtml, setValueHtml] = useState(null);
    const [searchParams, setSearchParams] = useState({});
    let [startDate, setStartDate] = useState({ date: new Date() });
    let [endDate, setEndDate] = useState({ date: new Date() });
    const autoC = useRef(null);

    const handleSearchOptionChange = (event, value) => {
        console.log("value: ", value );
        if (!value) return;
        setSelectedSearchOption(value);
        setLoading( true );
        // fetchPlants(value.label);

        switch (value.label) {
            case 'Plant Search':
                PlantsService.getPlants().then(
                    (response) => {
                        console.log("plants response: ", response);
                        let names = _.pluck(response.data.result, 'externalID');
                        // names = names.slice(0, 5000);
                        // let unique_names = _.uniq( names );
                        // console.log("names: ", names );
                        let options = [];

                        _.each(names, function (value, key) {
                            var obj = {
                                id: key + 1,
                                value: value,
                                name: value
                            };
                            options.push(obj);
                        });
                
                        const uniqueNames = _.uniq(options, 'name');
                        // console.log("Filtered names: ", uniqueNames);
                        let sorted = _.sortBy(uniqueNames, 'name');
                        // console.log('sorted: ', sorted );
                        setPlants( sorted );
                        setLoading(false);
                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                    }
                )
                break;
            case 'Parent Search':
                PlantsService.getPlants().then(
                    (response) => {
                        console.log("plants response: ", response);
                        let names = _.pluck(response.data.result, 'externalID');
                        // names = names.slice(0, 5000);
                        // let unique_names = _.uniq( names );
                        // console.log("names: ", names );
                        let options = [];

                        _.each(names, function (value, key) {
                            var obj = {
                                id: key + 1,
                                value: value,
                                name: value
                            };
                            options.push(obj);
                        });

                        const uniqueNames = _.uniq(options, 'name');
                        // console.log("Filtered names: ", uniqueNames);
                        let sorted = _.sortBy(uniqueNames, 'name');
                        // console.log('sorted: ', sorted );
                        setPlants( sorted );
                        setLoading(false);

                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                    }
                )
                break;
            case 'Cross Search':
                // Get Crosses 
                PlantsService.getCrosses().then(
                    (response) => {
                        console.log("crosses response: ", response);
                        let names = _.pluck(response.data.result, 'externalID');
                        // names = names.slice(0, 5000);
                        // let unique_names = _.uniq( names );
                        // console.log("names: ", names);
                        let options = [];
                        _.each(names, function (value, key) {
                            if (value) {
                                var obj = {
                                    id: key + 1,
                                    value: value,
                                    name: value
                                };
                                options.push(obj);
                            }
                        });
                        const uniqueNames = _.uniq(options, 'name');
                        // console.log("Filtered names: ", uniqueNames);
                        let sorted = _.sortBy(uniqueNames, 'name');
                        // console.log('sorted: ', sorted );
                        setPlants( sorted );
                        setLoading(false);
                    },
                    (error) => {
                        console.log("crosses error: ", error);
                        setLoading(false);
                    }
                )
                break;
            case 'Tag Search':
                PlantsService.getTags(value).then(
                    (response) => {
                        console.log("tags response: ", response);
                        let names = _.pluck(response.data.result, 'Note');
                        // names = names.slice(0, 5000);
                        // let unique_names = _.uniq( names );
                        // console.log("names: ", names);
                        var sorted = names.sort((a, b) => a - b);

                        let options = [];
                        _.each( sorted, function (value, key) {
                            if (value) {
                                var obj = {
                                    id: key + 1,
                                    value: value,
                                    name: value
                                };
                                options.push(obj);
                            }
                        });
                        var noDupes = _.uniq(options, 'name');
                        
                        setPlants(noDupes);
                        setLoading(false);
                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                    })
                break;
            case 'Variety Search':
                PlantsService.getVarieties(value).then(
                    (response) => {
                        console.log("variety response: ", response);
                        let names = _.pluck(response.data.result, 'VarietyName');
                        // names = names.slice(0, 5000);
                        // let unique_names = _.uniq( names );
                        // console.log("names: ", names);
                        let options = [];
                        _.each(names, function (value, key) {
                            if (value) {
                                var obj = {
                                    id: key + 1,
                                    value: value,
                                    name: value
                                };
                                options.push(obj);
                            }
                        });
                        const uniqueNames = _.uniq(options, 'name');
                        // console.log("Filtered names: ", uniqueNames);
                        let sorted = _.sortBy(uniqueNames, 'name');
                        // console.log('sorted: ', sorted );
                        setPlants( sorted );
                        setLoading(false);
                    },
                    (error) => {
                        console.log("error: ", error);
                        setLoading(false);
                    })
                break;
            default:
                // code block
                console.log("something went wrong..");
                setLoading(false);
        }

    };

    const handleInputChange = (event, value) => {
        console.log("New Value:", value);
        if (!value) return;
        setLoading(true);

        switch (selectedSearchOption.label) {
            case 'Plant Search':
                // Send Request
                PlantsService.searchPlants(value).then(
                    (response) => {
                        console.log("search response: ", response);
                        
                    },
                    (error) => {
                        console.log("error: ", error);
                        
                    })
                break;
            case 'Parent Search':
                // Send Request
                PlantsService.searchPlants(value).then(
                    (response) => {
                        console.log("search response: ", response);
                        
                    },
                    (error) => {
                        console.log("error: ", error);
                        
                    })
                break;
            case 'Cross Search':
                PlantsService.searchCrosses(value).then(
                    (response) => {
                        console.log("search response: ", response);
                        
                    },
                    (error) => {
                        console.log("error: ", error);
                        
                    })
                break;
            case 'Tag Search':
                PlantsService.searchTags(value).then(
                    (response) => {
                        console.log("tags response: ", response);
                        
                    },
                    (error) => {
                        console.log("error: ", error);
                        
                    })
                break;
            case 'Variety Search':
                PlantsService.searchVarieties(value).then(
                    (response) => {
                        console.log("varieties response: ", response);
                        
                    },
                    (error) => {
                        console.log("error: ", error);
                        
                    })
                break;
            default:
                // code block
                console.log("got here, something went wrong.");

        }


    };

    // Phenotypes
    function handleStartDate(event) {
        console.log("event: ", event);
        let d = new Date(event);
        console.log("d: ", d);
        setStartDate({ date: d });
        startDate.date = d;
    }

    function handleEndDate(event) {
        console.log("event: ", event);
        let d = new Date(event);
        console.log("d: ", d);
        setEndDate({ date: d });
        endDate.date = d;
    }

    const handlePhenotypeOptionChange = async (event, value) => {
        if (!value) return;
        console.log("value: ", value);
        setLoading(true);
        setPhenotypeMessage(true);
        try {
            const response = await GroupService.getPhenotypeSearchType({
                Type: value.PhenotypeName,
                company: "Burchell"
            });
            const category = response.result[0].Category;
            const options = response.result[0].Search || [];
            console.log("category: ", category, "options: ", options);
            setCurrentPhenotype(value);
            buildValueHtml(category, options);
            setPhenotypeMessage(false);
            setLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setPhenotypeMessage(false);
            setLoading(false);
        }
    };

    const buildValueHtml = (category, options) => {
        let content;
        switch (category) {
            case 'Categorical':
            case 'Genotype':
                setSearchParams({ value: options[0] || 'All' }); // Set the first option as default or 'All' if options are empty
                content = (
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel>Value</InputLabel>
                        <Select
                            defaultValue={options[0]}
                            label="Value"
                            onChange={(e) => setSearchParams({ value: e.target.value })}
                        >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
                break;

            case 'Float':
            case 'Percent':
                if (options.Min === undefined || options.Max === undefined) {
                    console.error("Error: Min or Max values are not defined in options.");
                    return;
                }
                setSearchParams({ min: options.Min, max: options.Max }); // Set default Min and Max from options
                content = (
                    <div>
                        <TextField
                            label="Min Value"
                            type="number"
                            defaultValue={options.Min}
                            onChange={(e) => setSearchParams(params => ({ ...params, min: e.target.value }))}
                            style={{ marginTop: "10px" }}
                            fullWidth
                        />
                        <TextField
                            label="Max Value"
                            type="number"
                            defaultValue={options.Max}
                            onChange={(e) => setSearchParams(params => ({ ...params, max: e.target.value }))}
                            style={{ marginTop: "10px" }}
                            fullWidth
                        />
                    </div>
                );
                break;

            case 'TimeStamp':
                const currentDate = new Date();
                setSearchParams({ startDate: currentDate, endDate: currentDate }); // Set default start and end dates to current date
                content = (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <DatePicker
                                date={startDate.date}
                                setDate={handleStartDate}
                                display="Start Date"
                                style={{ marginTop: "10px" }}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <DatePicker
                                date={endDate.date}
                                setDate={handleEndDate}
                                display="End Date"
                                style={{ marginTop: "10px" }}
                            />
                        </div>
                    </>
                );
                break;

            default:
                content = null;
        }

        setValueHtml(content); // Set the UI elements in the component's state for display
    };

    const searchTerms = () => {
        if (!currentPhenotype) {
            console.log("No phenotype selected.");
            return;
        }
        setLoading(true);

        console.log("currentPhenotype: ", currentPhenotype);
        console.log("searchParams: ", searchParams);
        // return;

        // Create a more structured search query based on the phenotype type
        let search = " -PhenotypeSearch ";
        switch (currentPhenotype.MeasurementType) {
            case 'Categorical':
                console.log("found cat: ");
                // Handle Alll
                if (currentPhenotype.PhenotypeValue === 'All') { currentPhenotype.PhenotypeValue = "FilterEmpty"; }
                // Fix Parentheses
                var phenoValue = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + phenoValue;
                break;
            case 'Genotype':
                console.log("found geno: ");
                // Handle Alll
                if (searchParams.value === 'All') {
                    searchParams.value = "FilterEmpty";
                }
                var p = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + p;
                break;
            case 'Descriptive':
                console.log("found desc: ");
                var pV = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + pV;
                break;
            case 'Float':
                console.log("found float: ");
                var minMax = searchParams.min + ":" + searchParams.max;
                search = search + currentPhenotype.PhenotypeName + ":" + minMax;
                break;
            case 'Percent':
                console.log("found percent: ");
                var m = searchParams.min + ":" + searchParams.max;
                search = search + currentPhenotype.PhenotypeName + ":" + m;
                break;
            case 'TimeStamp':
                console.log("found time stamp: ");
                // console.log("value: ", value );
                var ob = {
                    StartDate: DateTime.fromJSDate(startDate.date).toLocaleString(),
                    EndDate: DateTime.fromJSDate(endDate.date).toLocaleString()
                };
                search = search + currentPhenotype.PhenotypeName + ":" + "DM:" + ob.StartDate + ":" + ob.EndDate;
                break;
            default:
                console.warn("didn't find measruement type.");
        }

        let obj = {
            idCompany: 1,
            company: "Burchell",
            search: search
        };

        console.log("Search object: ", obj); // Debug output to see what we are sending
        setSearchResults([]);
        setPhenotypeMessage(true);

        // setLoading(false);
        // return;
        PlantsService.pedigreeSearchTerms(obj).then(
            response => {
                console.log("Search response:", response);
                // setValueHtml( null );
            },
            error => {
                console.error("Search error:", error);
                setLoading(false);
            }
        );
    };

    useEffect( ()=> {
        // Get Plants
        PlantsService.getPlants().then(
            response => {
                console.log("plants response: ", response.data);
                const names = _.pluck(response.data.result, 'externalID').map((name, index) => ({
                    id: index + 1,
                    value: name,
                    name: name
                })).filter(item => item.name !== null);  // Filtering out entries where name is null
                
                // Removing duplicates based on the 'name' property
                const uniqueNames = _.uniq(names, false, item => item.name);
                
                console.log("Filtered names: ", uniqueNames);
                setPlants(_.sortBy(uniqueNames, 'name'));
                setLoading(false);
            },
            error => {
                console.error("Fetch plants error:", error);
                setLoading(false);
            }
        );
        // Get Phenotypes
        GroupService.getPhenotypes().then(
            (response) => {
                console.log("phenos response: ", response.data );
                /** Tidy Search Phenos */
                var arr = [];
                _.each(response.data.result, function (value) {
                    switch (value.MeasurementType) {
                        case "Categorical":
                            arr.push(value);
                            break;
                        case "Boolean":
                            arr.push(value);
                            break;
                        case "Genotype":
                            arr.push(value);
                            break;
                        case "Integer":
                            arr.push(value);
                            break;
                        case "Float":
                            arr.push(value);
                            break;
                        case "Percent":
                            arr.push(value);
                            break;
                        case "TimeStamp":
                            arr.push(value);
                            break;
                        default:
                            // console.warn("something went wrong.");
                            return;
                    }
                });

                var sorted = _.sortBy(arr, "PhenotypeName");
                // console.log('sorted: ', sorted );
                setPhenotypes(sorted);
            },
            (error) => {
                console.log("error: ", error);
            }
        )
    },[]);

    return (
        <Paper elevation={3} sx={{ p: 4, marginBottom: "15px", flexGrow: 1 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Step 2: Search Form</Typography>
        <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
            You can search by plants descriptors or by phenotypes
        </Alert>
        {phenotypeMessage && (
            <Alert severity="warning">
               Phenotype search may take a moment, please wait...
            </Alert>
        )}
        {loading && <CircularProgress style={{ marginTop: "10px" }} />}
        {/* Plant Search Section */}
        <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>Plant Search</Typography>
            <Box sx={{ mb: 2 }}>
                <Autocomplete
                    id="search-options"
                    options={searchOptions}
                    defaultValue={searchOptions[0]}
                    getOptionLabel={(option) => option.label}
                    onChange={handleSearchOptionChange}
                    renderInput={(params) => <TextField {...params} label="Search Options" />}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Autocomplete
                    id="plant-search"
                    options={plants}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={handleInputChange}
                    renderInput={(params) => <TextField {...params} label="Select a plant" />}
                />
            </Box>
        </Box>
    
        {/* Phenotype Search Section */}
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>Phenotype Search</Typography>
            <Autocomplete
                disablePortal={false}
                ref={autoC}
                options={phenotypes}
                getOptionLabel={(option) => option.PhenotypeName || ''}
                onChange={handlePhenotypeOptionChange}
                renderInput={(params) => <TextField {...params} label="Phenotypes" variant="outlined" fullWidth />}
            />
            {valueHtml}
        </Box>
    
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
            <Button className="float-right" variant="contained" startIcon={<SearchIcon />} onClick={searchTerms} color="primary">
                Search
            </Button>
        </Box>
    </Paper>
    
    );
};

export default SearchPlants;


function replaceParentheses(val) {
    var string = val.toString();
    var substring = "(";
    var test = string.indexOf(substring) !== -1;
    if (test) {
        console.log("got here!!!!!")
        // Found Parentheses replace them! 
        val = val.replace("(", "OPENPARENTHESIS");
        val = val.replace(")", "CLOSEPARENTHESIS");
        return val;
    } else {
        return val;
    }
}
