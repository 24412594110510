import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import GroupIcon from "@material-ui/icons/Group";
import RadarIcon from "@material-ui/icons/Timeline";
import ChatIcon from "@material-ui/icons/Chat";
import MapIcon from "@material-ui/icons/Map";
import UserService from "../services/user.service";
import "bootstrap/dist/css/bootstrap.min.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
  },
  header: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  },
  content: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  card: {
    maxWidth: 345,
    margin: "auto",
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#fafafa",
  },
  icon: {
    fontSize: 50,
    color: "#4caf50",
  },
  button: {
    marginTop: theme.spacing(2),
    color: "#fff",
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  footer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  },
  loginSection: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  loginButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#333333",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        console.log("response: ", response);
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  const features = [
    {
      title: "Search Pedigree Database",
      description: "Allows users to search the pedigree database with multiple query options.",
      icon: <SearchIcon className={classes.icon} />,
      link: "/search"
    },
    {
      title: "Manage Plant Groups",
      description: "Create groups of plants and manage those groups efficiently.",
      icon: <GroupIcon className={classes.icon} />,
      link: "/groups"
    },
    {
      title: "Build Radar Charts",
      description: "Generate radar charts for any plant in the database for better visualization.",
      icon: <RadarIcon className={classes.icon} />,
      link: "/charts"
    },
    {
      title: "Phenotype GPT",
      description: "Chat with our phenotype database to get insights and information.",
      icon: <ChatIcon className={classes.icon} />,
      link: "/chat"
    },
    {
      title: "Drone Data Visualization",
      description: "Use Google Maps to visualize tree data from drones in the field.",
      icon: <MapIcon className={classes.icon} />,
      link: "/drone"
    },
  ];

  const { user: currentUser } = useSelector((state) => state.auth);
  console.log("current user: ", currentUser);

  return (
    <Container className={classes.root}>
      <Paper className={classes.header} elevation={3}>
        <Typography variant="h3" component="h1">
          Burchell Pedigree Platform
        </Typography>
        <Typography variant="h6" component="p">
          Improving human health and food security through innovations in agriculture
        </Typography>
      </Paper>

      {currentUser ? (
        <Grid container spacing={4} className={classes.content}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card} elevation={3}>
                {feature.icon}
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {feature.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    className={classes.button}
                    component={Link}
                    to={feature.link}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className={classes.loginSection}>
          <Typography variant="h6" component="p">
            To access the full features of our platform, please log in.
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to="/login"
          >
            Login
          </Button>
        </div>
      )}

      <Paper className={classes.footer} elevation={3}>
        <Typography variant="body1" component="p">
          {content}
        </Typography>
      </Paper>
    </Container>
  );
};

export default Home;
