import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;

const buildChart = (sample) => {
    // console.log("from service: ", sample );
    return axios
        .post(API_URL + "charts", { sample }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getPlantLocations = (sample) => {
    // console.log("from service: ", sample );
    return axios
        .post(API_URL + "getPlantLocations", { sample }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getBlossomCounts = (plant) => {
    // console.log("from service: ", plant );
    return axios
        .post(API_URL + "getBlossomCounts", { plant }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getPhenotypesFromId = (plant) => {
    // console.log("from service: ", plant );
    return axios
        .post(API_URL + "getPhenotypesFromId", { plant }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

const getAllBlossomCounts = (plant) => {
    // console.log("from service: ", plant );
    return axios
        .post(API_URL + "getAllBlossomCounts", { plant }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};



const ChartService = {
    buildChart,
    getPlantLocations,
    getBlossomCounts,
    getPhenotypesFromId,
    getAllBlossomCounts,
};

export default ChartService;
