import axios from "axios";

// const API_URL = "http://localhost:8080/api/auth/";
// const API_URL = "http://192.168.12.110:8080/api/auth/";
import URL from '../common/GetUrl';
var url = URL();
// url = url.replace("test","auth");
// console.log("url: ", url );
const API_URL = url;

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const authService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default authService;


