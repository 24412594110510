import React, { useEffect, useState } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';

const TimeSeriesChart = ( props ) => {
    let { data } = props;
    // console.log("data: ", data );
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            const processed = data.map(entry => ({
                ...entry,
                TimeStamp: new Date(entry.TimeStamp).toLocaleDateString('en-US')
            }));
            // console.log("processed: ", processed);
            setProcessedData(processed);
        }
    }, [data]);

    return (
        <>
            {processedData.length > 0 && (
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Bloom Count Over Time
                        </Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={processedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="TimeStamp" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Blossom_Count" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default TimeSeriesChart;
