import React, { useState } from "react";
import _ from 'underscore';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    TextField,
    Box,
    Button,
} from "@material-ui/core";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SearchIcon from "@material-ui/icons/Search";


import PlantsService from "../services/plants.service";

function BarcodeScan(props) {
    const [textInput, setTextInput] = useState({
        searchTerm: ""
    });

    const handleTextInputChange = event => {
        // event.target.value = "BBx0P152A1"; 
        setTextInput({
            searchTerm: event.target.value
        });
    };

    const searchBarcode = () => {
        console.log("search: ", textInput );
        props.setSpinner( true );
        var obj = {
            idCompany: 1,
            company: "Burchell",
            command: textInput.searchTerm
        };
        console.log("obj: ", obj );
        // Send Request
        PlantsService.searchBarcode( obj ).then(
            (response) => {
                console.log("barcode response: ", response );
                 // Success
                 var results = [];
                 _.each( response.result, function(value) {
                     // console.log("value: ", value );
                     var obj = {
                         idPlant: value.idPlant,
                         PlantCode: value.PlantCode,
                         AlternatePlantID: value.AlternatePlantID,
                         'Verinomics Code': value.PlantCode,
                         'Company Code': value.AlternatePlantID,
                         CrossCode: value.CrossCode,
                         AlternateCrossID: value.AlternateCrossID
                     };
                     results.push( obj );
                 });
                //  console.log("search results: ", results );
                props.setCommand( response.cmd );
                setTextInput({ searchTerm: "" });
                props.setSearchResults(results);
                props.setSpinner(false);
            }, 
            (error) => {
                console.log("error: ", error );
                props.setErrorMessage( "No plants found in our database, try again." );
                props.setSpinner( false );
            }
        )
    }

  return (
    <>

          <Card style={{ marginTop: "25px" }}>
              {/* <CardHeader
                  title={<Typography variant="h6">Plant Code Scan</Typography>}
              /> */}
            <CardHeader title="Plant Code Scan" />

              <CardContent>
                  <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%", padding: "10px" }}>
                      <QrCode2Icon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                      <TextField
                          id="scan-barcode"
                          label="Scan Plant Code"
                          variant="standard"
                          onChange={handleTextInputChange}
                          fullWidth
                      />
                  </Box>
              </CardContent>
              <CardActions>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={searchBarcode}
                      startIcon={<SearchIcon />}
                  >
                      Search
                  </Button>
              </CardActions>
          </Card>
        {/* <div className="card" style={{marginTop: "25px"}}>
            <div className="card-header">
                <div className='card-title'> Plant Code Scan </div>
            </div>
            <div className='card-body'>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "100%", padding: "10px" }}>
                    <QrCode2Icon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField className="form-control" id="scan-barcode" label="Scan Plant Code" variant="standard" onChange= { handleTextInputChange } style={{width: "100%"}} />
                </Box>
            </div>
            <div className='card-footer'>
                <button className="btn btn-primary btn-sm" onClick={searchBarcode}> <SearchIcon /> Search </button>
            </div>
        </div> */}
    </>
  );
}


export default BarcodeScan;