import React, { useState, useEffect, useMemo } from "react";
import Papa from 'papaparse';
import UserService from "../services/user.service";
import ChartService from "../services/charts.service";
import TimeSeriesChart from './TimeSeriesChart';
import EventBus from "../common/EventBus";

import { GoogleMap, Marker, useJsApiLoader, LoadScript } from '@react-google-maps/api';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { Menu, Button, MenuItem, IconButton } from '@material-ui/core';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Tab, Tabs, Spinner } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Heatmap from "./Heatmap";

const center = {
  lat: 37.7755,
  lng: -120.7535
};

const Drone = () => {
  const [content, setContent] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [phenotypeData, setPhenotypeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const imgSrc = require("../img/googlemaps.png");
  const skySense = require("../img/skysense_v2.png");
  const [redirect, doRedirect] = useState(false)

  const [anchorElProcessed, setAnchorElProcessed] = useState(null);
  const [anchorElRaw, setAnchorElRaw] = useState(null);

  const handleProcessedClick = (event) => {
    setAnchorElProcessed(event.currentTarget);
  };

  const handleRawClick = (event) => {
    setAnchorElRaw(event.currentTarget);
  };

  const handleProcessedClose = () => {
    setAnchorElProcessed(null);
  };

  const handleRawClose = () => {
    setAnchorElRaw(null);
  };

  const [blossomData, setBlossomData] = useState([]);

  const style = { marginRight: "10px" }

  let files = [
    // "filtered_data_with_blossom_counts.csv",
    // "filtered_data_with_blossom_counts_2ft.csv",
    // "filtered_data_with_blossom_counts_3ft.csv",
    // "combined_blossom_counts.csv",
    "flight_log_2024-02-19_grid.csv",
    "flight_log_2024-02-20_grid.csv ",
    "flight_log_2024-02-27_grid.csv ",
    "flight_log_2024-03-04_grid.csv ",
    "flight_log_2024-03-08_grid.csv ",
    "flight_log_2024-03-09_grid.csv ",
    "flight_log_2024-03-16_grid.csv",
    "flight_log_2024-03-28_grid.csv ",
    // "Flights.csv",
    // "Flights_v2.csv",
    "Flights_v3.csv",

  ]

  let rawFiles = [
    "flight_log_2024-02-19_metadata.csv",
    "flight_log_2024-02-20_metadata.csv",
    "flight_log_2024-02-21_metadata.csv",
    "flight_log_2024-02-28_metadata.csv",
    "flight_log_2024-02-26_metadata.csv",
    "flight_log_2024-02-27_metadata.csv",
    "flight_log_2024-03-04_metadata.csv",
    "flight_log_2024-03-05_metadata.csv",
    "flight_log_2024-03-06_metadata.csv",
    "flight_log_2024-03-07_metadata.csv",
    "flight_log_2024-03-08_metadata.csv",
    "flight_log_2024-03-09_metadata.csv",
    "flight_log_2024-03-11_metadata.csv",
    "flight_log_2024-03-16_metadata.csv",
    "flight_log_2024-03-28_metadata.csv",
    "Flights.csv",
  ];

  const containerStyle = {
    width: '100%',
    height: '100%'
  };
  const libraries = useMemo(() => ['visualization'], []);
  const googleMapsApiKey = 'AIzaSyCyS0717Gue3aDE3f8nmhYXiIYJDUDHKM4';

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries,
  });

  const onMarkerClick = (location) => {
    setSelectedLocation(null);
    setTimeSeriesData([]);
    setPhenotypeData([]);
    console.log("clicked: ", location);
    setTimeout(() => {
      setSelectedLocation(location);
      setShowModal(true);
    })
  };

  const handleModalClose = () => setShowModal(false);

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const centerPoint = new window.google.maps.LatLng(center.lat, center.lng);
    map.setCenter(centerPoint);
    map.setZoom(19); // Adjust this value as needed for the desired initial zoom level

    setMap(map);
  }, [center]);  // Include 'center' to ensure re-calculation if it changes

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const fetchAndProcessCSV = async (file) => {
    console.log('file: ', file);
    try {
      // Construct the URL relative to the public directory
      const url = `${process.env.PUBLIC_URL}/processed_flights/${file.trim()}`;
      const response = await fetch(url);
      const csvText = await response.text();
      Papa.parse(csvText, {
        header: true,
        complete: function (results) {
          console.log("csv: ", results);
          const markers = results.data.map(item => ({
            lat: parseFloat(item.Latitude),
            lng: parseFloat(item.Longitude),
            metadata: item,
          }));
          setLocations(markers);
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
        }
      });
    } catch (error) {
      console.error('Failed to fetch and parse CSV:', error);
    }
  };

  const fetchRawCSV = async (file) => {
    console.log('file: ', file);
    try {
      // Construct the URL relative to the public directory
      const url = `${process.env.PUBLIC_URL}/raw_flights/${file.trim()}`;
      const response = await fetch(url);
      const csvText = await response.text();
      Papa.parse(csvText, {
        header: true,
        complete: function (results) {
          console.log("csv: ", results);
          const markers = results.data.map(item => ({
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lon),
            metadata: item,
          }));
          setLocations(markers);
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
        }
      });
    } catch (error) {
      console.error('Failed to fetch and parse CSV:', error);
    }
  };

  const handleDropdownChange = (event) => {

    // fetchAndProcessCSV( value );
  };

  const handleBloomTime = (event) => {
    console.log('location: ', selectedLocation)
    let idPlant = selectedLocation.metadata.Plant_idPlant;
    console.log("idPlant: ", idPlant);
    setLoading(true);
    // Send Request 
    ChartService.getBlossomCounts(idPlant).then(
      (response) => {
        console.log('blossom response: ', response);
        setTimeSeriesData(response.result);
        setLoading(false);
      },
      (error) => {
        console.log('error: ', error);
        alert("Bloom Time Series data only works with database locations.");
        setLoading(false);
      }
    )

  }

  const getDatabaseLocations = () => {
    // Get Plant Locations
    ChartService.getPlantLocations({}).then(
      (response) => {
        console.log("locations success: ", response);

        const markers = response.result.map(item => {
          const [lat, lng] = item.gps.split(',').map(coord => parseFloat(coord.trim()));
          return {
            lat: lat,
            lng: lng,
            metadata: item,
          };
        });
        console.log('markers: ', markers);
        setLocations(markers);

      },
      (error) => {
        console.log("location error: ", error);
      }
    )
  }

  const handlePhenotypeClick = () => {
    let idPlant = selectedLocation.metadata.Plant_idPlant;
    console.log("idPlant: ", idPlant);
    setLoading(true);
    // Send Request
    ChartService.getPhenotypesFromId(idPlant).then(
      (response) => {
        console.log('phenotype response: ', response);
        let data = response.result;
        data = data.map(entry => {
          // If the MeasurementType is "TimeStamp" and PhenotypeMeasurementcol is null, use the value from Notes
          if (entry.MeasurementType === "TimeStamp" && entry.PhenotypeMeasurementcol === "null") {
            entry.PhenotypeMeasurementcol = entry.Notes;
          }
          return entry;
        }).filter(entry => {
          // Remove entries where PhenotypeMeasurementcol is null or an empty string, except for TimeStamp types which have been fixed
          return entry.PhenotypeMeasurementcol !== "null" && entry.PhenotypeMeasurementcol !== "";
        });
        console.log("data: ", data);

        if (data.length === 0) {
          console.warn("no phenos found..");
          setTimeout(() => {
            setLoading(false);
            alert("No Phenotypes found.");
          }, 1000);
        } else {
          setTimeout(() => {
            setPhenotypeData(data);
            setLoading(false);
          }, 500);
        }

      },
      (error) => {
        console.log('error: ', error);
        setLoading(false);
      }
    )


  }

  const handleSelect = (eventKey) => {
    if (eventKey === 'bloom') {
      handleBloomTime();
    } else if (eventKey === 'phenotypes') {
      handlePhenotypeClick();
    }
    // Add more conditions if you have other onClick functions for other tabs
  };

  useEffect(() => {
    // Get Content
    UserService.getPublicContent().then(
      (response) => {
        console.log("response: ", response);
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );

    // Get Plant Locations
    ChartService.getPlantLocations({}).then(
      (response) => {
        console.log("locations success: ", response);

        const markers = response.result.map(item => {
          const [lat, lng] = item.gps.split(',').map(coord => parseFloat(coord.trim()));
          return {
            lat: lat,
            lng: lng,
            metadata: item,
          };
        });
        console.log('markers: ', markers);
        setLocations(markers);

      },
      (error) => {
        console.log("location error: ", error);
      }
    );

    // Check User
    UserService.getUserBoard().then(
      (response) => {
        console.log("user response: ", response);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log("error: ", _content);
        doRedirect(true);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

  }, []);

  return isLoaded ? (
    <>
      <div className="container">
        <Container fluid>
          <Row className="jumbotron justify-content-center align-items-center py-5">
            <Col xs={12} md={6} className="text-center">
              <h3>Burchell Drone Data</h3>
            </Col>
            <Col xs={12} md={6} className="text-center">
              <Image src={skySense.default} fluid alt="logo" width={200} />
            </Col>
          </Row>
        </Container>
        <Card className="card-container">
          <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={imgSrc.default} alt="logo" height={50} />

            <div>
              {/* Raw Files */}
              <Button
                aria-controls="raw-menu"
                aria-haspopup="true"
                onClick={handleRawClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
                style={{ marginRight: 8 }}
              >
                Raw Flight Logs
              </Button>
              <Menu
                id="raw-menu"
                anchorEl={anchorElRaw}
                keepMounted
                open={Boolean(anchorElRaw)}
                onClose={handleRawClose}
              >
                {rawFiles.map((file, index) => (
                  <MenuItem key={index} onClick={() => { fetchRawCSV(file); handleRawClose(); }}>
                    {file}
                  </MenuItem>
                ))}
              </Menu>
              {/* Proccessed Files */}
              <Button
                aria-controls="processed-menu"
                aria-haspopup="true"
                onClick={handleProcessedClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
                style={{ marginRight: 8 }}
              >
                Processed Flight Logs
              </Button>
              <Menu
                id="processed-menu"
                anchorEl={anchorElProcessed}
                keepMounted
                open={Boolean(anchorElProcessed)}
                onClose={handleProcessedClose}
              >
                {files.map((file, index) => (
                  <MenuItem key={index} onClick={() => { fetchAndProcessCSV(file); handleProcessedClose(); }}>
                    {file}
                  </MenuItem>
                ))}
              </Menu>
              {/* Database */}
              <Tooltip title="These are plants that we have in our database matched up with GPS locations from Skysense">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={getDatabaseLocations}
                >
                  Verinomics Database Locations
                </Button>
              </Tooltip>
            </div>
          </Card.Header>

          <Card.Body className="card-map-container" style={{ width: "100%" }}>
            {/* Google Maps */}
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              mapTypeId="satellite"
            >
              {locations.map((loc, idx) => (
                <Marker key={idx} position={{ lat: loc.lat, lng: loc.lng }} onClick={() => onMarkerClick(loc)} />
              ))}
            </GoogleMap>

          </Card.Body>
        </Card>

        <hr />
        {/* Heatmap */}
        <Card className="card-container" style={{paddingBottom: "100px"}}>
          <Card.Header>
            <img src={imgSrc.default} alt="logo" height={50} style={style} />
            <Typography variant="h5" className="float-right">
              Heatmap of Blossom Counts
            </Typography>
          </Card.Header>
          <Card.Body className="card-map-container" style={{ width: "100%" }}>
            <Heatmap
              setShowModal={setShowModal}
              setSelectedLocation={setSelectedLocation}
            />
          </Card.Body>
        </Card>

        {/* Modal */}
        <Modal show={showModal}
          onHide={handleModalClose}
          dialogClassName="wide-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Location Details</Modal.Title>
            <button type="button" className="close" aria-label="Close" style={{ cursor: "pointer" }} onClick={handleModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="location"
              id="uncontrolled-tab-example"
              onSelect={handleSelect}
              variant="pills"

            >
              <Tab eventKey="location" title="Location Details">
                {selectedLocation ? (
                  <ListGroup>
                    {Object.entries(selectedLocation.metadata)
                      .map(([key, value], index) => (
                        <ListGroup.Item key={index} className="list-group-item">
                          <strong>{key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}:</strong> {value}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                ) : <p>No data available.</p>}
              </Tab>
              <Tab eventKey="phenotypes" title="Phenotypes" onClick={handlePhenotypeClick}>
                {phenotypeData.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="phenotype table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Phenotype Name</TableCell>
                          <TableCell>Measurement</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Notes</TableCell>
                          <TableCell>Data File</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {phenotypeData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.PhenotypeName}</TableCell>
                            <TableCell>{row.PhenotypeMeasurementcol}</TableCell>
                            <TableCell>{row.PhenotypeDescription}</TableCell>
                            <TableCell>{row.MeasurementType}</TableCell>
                            <TableCell>{row.Notes}</TableCell>
                            <TableCell>{row.DataFile ? <a href={row.DataFile}>View File</a> : "N/A"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : <p>No phenotype data available.</p>}
              </Tab>
              <Tab eventKey="bloom" title="Bloom Time Series" onClick={handleBloomTime}>
                <TimeSeriesChart data={timeSeriesData} />
              </Tab>
            </Tabs>
            {loading && (
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>

  ) : <>
    <p>Google Maps is loading..</p>
  </>


};

export default Drone;
