import React, { useState, useEffect, useCallback } from "react";
import PlantsService from "../services/plants.service";
import _ from "underscore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {  Box, Card, CardContent, CardHeader, TextField, Typography } from "@material-ui/core";
import Alert from 'react-bootstrap/Alert';

import { List } from "react-virtualized";

const searchOptions = [
    { label: "Plant Search" },
    { label: "Parent Search" },
    { label: "Cross Search" },
    { label: "Variety Search" },
    { label: "Tag Search" },
];

const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 36;

    return (
        <div ref={ref}>
            <div {...other}>
                <List
                    height={250}
                    width={300}
                    rowHeight={itemSize}
                    overscanCount={5}
                    rowCount={itemCount}
                    rowRenderer={props => {
                        return React.cloneElement(children[props.index], {
                            style: props.style
                        });
                    }}
                    role={role}
                />
            </div>
        </div>
    );
});

const SearchForm = (props) => {
    const [plants, setPlants] = useState([]);
    const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0]);

    const inputChanged = (event, value, reason) => {
        if (value) {
            console.log("newVal: ", value);
            props.setSpinner(true);

            switch (selectedSearchOption.label) {
                case 'Plant Search':
                    PlantsService.searchPlants(value).then(
                        (response) => { },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Parent Search':
                    PlantsService.searchPlants(value).then(
                        (response) => { },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Cross Search':
                    PlantsService.searchCrosses(value).then(
                        (response) => { },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Tag Search':
                    PlantsService.searchTags(value).then(
                        (response) => { console.log("tags response: ", response); },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Variety Search':
                    PlantsService.searchVarieties(value).then(
                        (response) => { console.log("varieties response: ", response); },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                default:
                    console.log("got here, something went wrong.");
                    props.setSpinner(false);
            }
        }
    };

    const searchOptionChanged = (event, value, reason) => {
        if (value) {
            console.log('newVal: ', value);
            setSelectedSearchOption(value);

            switch (value.label) {
                case 'Plant Search':
                case 'Parent Search':
                    PlantsService.getPlants().then(
                        (response) => {
                            let names = _.pluck(response.data.result, 'externalID');
                            let options = names.map((name, key) => ({ id: key + 1, value: name, name }));
                            setPlants(_.sortBy(options, 'name'));
                        },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Cross Search':
                    PlantsService.getCrosses().then(
                        (response) => {
                            let names = _.pluck(response.data.result, 'externalID');
                            let options = names.map((name, key) => ({ id: key + 1, value: name, name })).filter(option => option.value);
                            setPlants(_.sortBy(options, 'name'));
                        },
                        (error) => { console.log("crosses error: ", error); }
                    );
                    break;
                case 'Tag Search':
                    PlantsService.getTags().then(
                        (response) => {
                            let names = _.pluck(response.data.result, 'Note').sort((a, b) => a - b);
                            let options = _.uniq(names.map((name, key) => ({ id: key + 1, value: name, name })), 'name');
                            setPlants(options);
                        },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                case 'Variety Search':
                    PlantsService.getVarieties().then(
                        (response) => {
                            let names = _.pluck(response.data.result, 'VarietyName');
                            let options = names.map((name, key) => ({ id: key + 1, value: name, name })).filter(option => option.value);
                            setPlants(_.sortBy(options, 'name'));
                        },
                        (error) => { console.log("error: ", error); }
                    );
                    break;
                default:
                    console.log("something went wrong..");
            }
        }
    };

    useEffect(() => {
        PlantsService.getPlants().then(
            (response) => {
                let names = _.pluck(response.data.result, 'externalID');
                let options = names.map((name, key) => ({ id: key + 1, value: name, name }));
                setPlants(_.sortBy(options, 'name'));
            },
            (error) => {
                console.warn("error: ", error);
                console.warn("plants error, redirect...");
            }
        );
    }, []);

    return (
        <Card>
            <CardHeader title="Search Form" />
            <CardContent>
                <Alert severity="info">
                    You are searching the database by {selectedSearchOption.label}
                </Alert>
                <Autocomplete
                    id="search-options"
                    disablePortal
                    getOptionLabel={(option) => option.label || ''}
                    getOptionSelected={useCallback((option, value) => option.label === value.label, [])}
                    options={searchOptions}
                    defaultValue={searchOptions[0]}
                    onChange={searchOptionChanged}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Search Options"
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Autocomplete
                    id="plant-search"
                    disablePortal
                    disableListWrap
                    selectOnFocus
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={useCallback((option, value) => option.value === value.value, [])}
                    ListboxComponent={ListboxComponent}
                    options={plants}
                    onChange={inputChanged}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Available Plants"
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
            </CardContent>
        </Card>
    );
}

export default SearchForm;
