import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import PlantsService from "../services/plants.service";

const NotesModal = (props) => {
    // console.log("props: ", props);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [textInput, setTextInput] = useState({
        notes: ""
    });
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);

    const handleTextInputChange = event => {
        setTextInput({
            notes: event.target.value
        });
    };

    function handleSaveButton() {
        props.setSpinner(true);
        var obj = {
            idPlant: props.currentPlant.idPlant,
            notes: textInput.notes,
        };
        console.log("obj: ", obj );
        // Send Request 
        PlantsService.addNotes( obj ).then(
            (response) => {
                console.log('notes response: ', response );
                props.setSpinner(false);
                setSuccessMessage("success");
                setTimeout(function() {
                    setSuccessMessage("");
                    handleClose();
                },2000);
            },
            (error) => {
                console.log("error: ", error );
                props.setSpinner(false);
                setErrorMessage("error");
                setTimeout(function() {
                    setErrorMessage("");
                },2000);
            }
        )
    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add Notes</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer"}}/>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="notes">Notes</label>
                                <textarea className="form-control" id="notes" rows="3" onChange= { handleTextInputChange }></textarea>
                            </div>
                        </div>
                    </div>

                    { successMessage && (
                        <div className="alert alert-success text-center" id="noteSuccess">
                            <strong>Success: </strong> Successfully created a note.
                        </div>
                    )}

                    { errorMessage && (
                        <div className="alert alert-danger text-center" id="noteError">
                            <strong> Error: </strong> Something went wrong, refresh the browser and try again.
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={handleSaveButton} startIcon={<SaveIcon />}>
                        Save Notes
                    </Button>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NotesModal;







