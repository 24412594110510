import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import GroupService from "../services/groups.service";

const PlantsGroups = (props) => {
    // console.log("props: ", props);
    const [ plantGroups, setPlantGroups] = React.useState([]);
    const [ successMessage, setSuccessMessage ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState( false );
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);

    let addPlants = (row) => {
        // Send Request
        console.log("row: ", row );
        var obj = {
            plants: props.searchResults,
            idDescriptor: row.idDescriptor
        };
        console.log("obj: ", obj );
        GroupService.addAllPlantsToGroup( obj ).then(
            ( response ) => {
                console.log('add response: ', response );
                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage(false)
                }, 2000);
            },
            ( error ) => {
                console.log("error: ", error );
                setErrorMessage(true);
                setTimeout(() => {
                    setErrorMessage(false)
                }, 2000);
            }
        )
    }

    useEffect( () => {
        // Get Plnat Groups
        GroupService.getPlantGroups().then(
            ( response ) => {
                // console.log("groups responpse: ", response );
                setPlantGroups( response.data.result );
            },  
            ( error ) => {
                console.log("error: ", error );
            }
        )
    }, []);

    return (
        <>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Add Plants to Group</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>

                    <div className="jumbotron">
                        <div className="alert alert-info text-center" >
                            <strong><i className="fas fa-info-circle"></i> info: </strong> Use this feature to add these selected plants into a group.
                        </div>
                        <h5 className="title">Selected Plants:</h5>
                        <ul className="list-group">
                                {
                                    props.searchResults.map( (value) => {
                                        return (
                                            <li className="list-group-item" key={value.idPlant}>
                                                { value['Company Code'] }, {value.PlantCode }
                                            </li>
                                        )
                                    })
                                }
                        </ul>
                    </div>
                    <div className="container">
                        <h5 className="title">Available Groups:</h5>
                        { successMessage && 
                            <div className="alert alert-success text-center" id="AddToGroupSuccess">
                                <strong><i className="fas fa-check-circle"></i> Success: </strong> Successfully added plants to this group.
                            </div>
                        }
                        { errorMessage && 
                            <div className="alert alert-danger text-center" id="AddToGroupError">
                                <strong><i className="fas fa-exclamation-triangle"></i> Error: </strong> Something went wrong, contact evan@verinomics.com
                            </div>
                        }
                        <ul className="list-group">
                                {
                                    plantGroups.map( (value) => {
                                        return (
                                            <li className="list-group-item" key={value.Name}>
                                                <div>
                                                    <i className="fas fa-check-circle fa-2x float-right"></i>
                                                </div>
                                                <div>
                                                    <Button className="float-right" variant="contained" size="small" onClick={ ()=> { addPlants( value ) }}> <AddIcon  style={{marginRight: "3px"}}/> Add to Group</Button>
                                                </div>
                                                {value.Name}
                                            </li>
                                        );
                                        
                                    })
                                }
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PlantsGroups;
