import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GroupService from "../services/groups.service";
import _ from "underscore";

const SummaryModal = (props) => {
    const [isOpened, setIsOpened] = useState(true);
    const [isGroupOpened, setIsGroupOpened] = useState(true);
    const [isCrossOpened, setIsCrossOpened] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [plantGroups, setPlantGroups ] = useState([]);

    // console.log("props: ", props );
    const summary = (props.plantSummary);
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);
    const phenotypes = props.plantPhenotypes;
    // console.log("pheno: ", phenotypes );

    function toggle() {
      setIsOpened(wasOpened => !wasOpened);
    }

    function toggleGroups() {
      setIsGroupOpened(wasOpened => !wasOpened);
    }

    function toggleCrosses() {
      setIsCrossOpened(wasOpened => !wasOpened);
    }

    function addPlantsToGroup( group ) {
      props.setSpinner(true);
      var obj = {
        idPlant: props.plantSummary[0].data,
        idDescriptor: group.idDescriptor
      };
      console.log("obj: ", obj );
      // Send Request
      GroupService.addPlantToGroup( obj ).then(
        (response) => {
          console.log("add plant response: ", response );
          group.HasGroup = true;
          setSuccessMessage("Added Plant");
          setTimeout(function() {
            setSuccessMessage("");
            props.setSpinner(false);
          },2000);
        },
        (error) => {
          console.log("error: ", error );
          setErrorMessage("something went wrong.");
          setTimeout(function() {
            setErrorMessage("");
            props.setSpinner(false);
          },2000);
        }
      )
      

    }

    let summaryData = () => {
      return summary.map((item, i) => {
        if (item.key !== "idPlant") {
          return <li key={i} className="list-group-item"> <b>{ item.key }</b> : {item.data} </li>
        } else {
          return null;
        }
      })
    }

    let MaternalCrosses = () => {
      if( props.maternalCrosses !== "None" ) {
        // console.log("found crosses..");
        let c = props.maternalCrosses ? props.maternalCrosses : [];
        return c.map( (value, key) => {
          return <div className="card" key={key}>
            <div className="card-body">
              <h5 className="card-title">CrossCode: {value.CrossCode}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Alternate ID: {value.AlternateID}</h6>
              <p className="card-text" style={{marginBottom: "0px"}}>MaternalParent: {value.MaternalParent}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>PaternalParent: {value.PaternalParent}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Maternal: {value.MaternalParentAlternateID}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Paternal: {value.PaternalParentAlternateID}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Notes: {value.Notes}</p>
            </div>
          </div>
        });
      } else {
        return <p>No Maternal Crosses</p>
      }
    }

    let PaternalCrosses = () => {
      if( props.paternalCrosses !== "None" ) {
        // console.log("found crosses..");
        let c = props.paternalCrosses ? props.paternalCrosses : [];
        return c.map( (value, key) => {
          return <div className="card" key={key}>
            <div className="card-body">
              <h5 className="card-title">CrossCode: {value.CrossCode}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Alternate ID: {value.AlternateID}</h6>
              <p className="card-text" style={{marginBottom: "0px"}}>MaternalParent: {value.MaternalParent}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>PaternalParent: {value.PaternalParent}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Maternal: {value.MaternalParentAlternateID}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Paternal: {value.PaternalParentAlternateID}</p>
              <p className="card-text" style={{marginBottom: "0px"}}>Notes: {value.Notes}</p>
            </div>
          </div>
        });
      } else {
        return <p>No Paternal Crosses</p>
      }
    }

    useEffect( () => {
      GroupService.getPlantGroups().then(
        (response) => {
          var arr = [];
          _.each(response.data.result, function (value) {
            _.each(props.plantDescriptors, function (val) {
              if (value.idDescriptor === val.idDescriptor) {
                value.HasGroup = true;
              } else {
                value.HasGroup = false;
              }
            });
            arr.push( value );
          });
          // console.log("arr: ", arr );
          setPlantGroups( arr );
        }, 
        (error) => {
          console.log("error: ", error );
        }
      )
    }, [props.plantDescriptors]);

  return (
    <>
    {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

    <Modal size="lg" show={ props.show } onHide={handleClose} dialogClassName="modal-width">
      <Modal.Header>
        <Modal.Title>Plant Summary</Modal.Title>
        <CloseIcon className="float-right" onClick={handleClose} /> 
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group">
                { summaryData() }
            </ul>
          </div>
          <div className="col-md-6">

            {/* Phenotypes */}
              {isOpened && (
                <>
                  <p className="card-text">
                    <strong>Phenotypes: </strong>
                    <span onClick={toggle} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityOffIcon/> Hide Phenotypes </span>
                  </p>
                  { (phenotypes === undefined || phenotypes.length === 0) && (
                    <>
                      <hr></hr>
                      <h5>No Phenotypes for this plant.</h5>
                      <hr></hr>
                    </>
                  )
                  }

                  { phenotypes && (
                    <>
                        {
                          phenotypes.map((value, key) => {
                            if (value.MeasurementType === "TimeStamp") {
                              return (
                                
                                <div className="card" key={key}>
                                    <div className="card-body">
                                      <h5 className="card-title"> {value.DisplayName} </h5>
                                      <p className="card-text"><b>{value.FormattedDate} </b> </p>
                                      <small> {value.MeasurementType}, {value.PhenotypeDescription}</small>
                                      <p> Author: {value.Name} </p>
                                    </div>
                                </div>

                                )
                                } else {
                                  let img;
                                  if( value.DataFile ) {
                                    img = value.DataFile.replace( '/nfs/WebserverStorage','');
                                  }
                                  let test = value.DataFile === null ? false : true;
                                  return (
                                    
                                    <div className="card" key={key}>
                                    { test && (
                                        <img 
                                          className="card-img-top" 
                                          src={img} 
                                          alt={key} 
                                          onError={(e) => {
                                            console.error('Image load error:', e.target.src);
                                          }}
                                        />
                                    ) }
                                    <div className="card-body">
                                      <h5 className="card-title"> {value.DisplayName} </h5>
                                      <p className="card-text"><b>{value.PhenotypeMeasurementcol} </b></p>
                                      <small>{value.MeasurementType}, {value.PhenotypeDescription} </small> 
                                      <p> Author: {value.Name} </p>
                                    </div>
                                </div>

                              )

                            }
                          })
                        }

                    </>
                  )
                  }
                </>
              )}

              {!isOpened && (
                <p className="card-text">
                  <strong>Phenotypes: </strong>
                  <span onClick={toggle} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityIcon /> Show Phenotypes </span>
                </p>
              )}

            {/* PlantGrouups */}
              {isGroupOpened && (
                <>
                  <p className="card-text">
                    <strong>Groups: </strong>
                    <span onClick={toggleGroups} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityOffIcon /> Hide Groups </span>
                  </p>

                  { successMessage !== "" && (
                    <div className="alert alert-success text-center">
                      <strong> Success: </strong> Successfully added this plant {props.Shasta} to a group.
                    </div>
                  )}

                  { errorMessage !== "" && (
                    <div className="alert alert-danger text-center">
                      <strong> Error: </strong> Something went wrong, contact Evan Buss.
                    </div>
                  )}

                  <ul className="list-group">

                    { plantGroups.map( (value) => {
                      // console.log("value: ", value );
                      var test = value.HasGroup;
                      return (
                        <li className="list-group-item" key={value.idDescriptor}>
                          { test && (
                            <CheckCircleIcon className="float-right" />
                          )}
                          { !test && (
                            <Button 
                              className="btn btn-sm btn-success float-right" 
                              size="small" 
                              variant="contained" 
                              onClick={ () => { addPlantsToGroup( value ) } }> 
                              <AddIcon />  
                              Add to Group 
                            </Button>
                          )}
                          { value.Name }
                        </li>
                      )
                    })
                    }

                  </ul>

                </>
              )}

              {!isGroupOpened && (
                <p className="card-text">
                  <strong>Groups: </strong>
                  <span onClick={toggleGroups} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityIcon /> Show Groups </span>
                </p>
              )}

            {/* Crosses */}
              {isCrossOpened && (
                <>
                  <p className="card-text">
                    <strong>Crosses: </strong>
                    <span onClick={toggleCrosses} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityOffIcon /> Hide Crosses </span>
                  </p>
                  
                  <div style={{maxHeight:"1000px", overflow:"auto"}}>
                    <h5>Maternal Crosses</h5><hr />
                    { MaternalCrosses() }
                    <h5>Paternal Crosses</h5><hr />
                    { PaternalCrosses() }
                  </div>
                </>
              )}

              {!isCrossOpened && (
                <p className="card-text">
                  <strong>Crosses: </strong>
                  <span onClick={toggleCrosses} style={{ color: "#0000FF", cursor: "pointer" }}> <VisibilityIcon /> Show Crosses </span>
                </p>
              )}

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
};

export default SummaryModal;
