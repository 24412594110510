import React, { useState } from 'react';
import { Form, Alert, Container, Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Table, Pagination } from 'react-bootstrap';
import { FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, TextField, TextareaAutosize, Paper, TablePagination, Typography, Box } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SyncIcon from '@mui/icons-material/Sync';

import GroupService from '../services/groups.service';

const MergeModal = (props) => {
    const {
        show,
        setShow,
        groups,
        setGroups,
    } = props;
    const [loading, setLoading ] = useState(false);
    const [leftGroup, setLeftGroup] = useState('');
    const [rightGroup, setRightGroup] = useState('');
    const [mergeType, setMergeType] = useState('inner');
    const [mergedData, setMergedData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState('');

    const [showForm, setShowForm ] = useState(false);
    const [groupName, setGroupName] = useState('');  // State to store the group name
    const [groupDescription, setGroupDescription] = useState('');  // State to store the group description
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [updateSuccess, setUpdateSuccess ] = useState(false);

    // Change page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Change rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0
    };

    // Filter rows
    const handleSearch = (event) => {
        setFilter(event.target.value.toLowerCase());
    };

    // Get filtered data
    const filteredData = mergedData.filter((row) => {
        const varietyName = row.VarietyName?.toLowerCase() ?? "";
        const externalID = row.externalID?.toLowerCase() ?? "";
        return varietyName.includes(filter) || externalID.includes(filter);
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

    const handleClose = () => setShow(false);

    const handleChangeLeftGroup = (event) => {
        console.warn("got here!!", event );
        console.log("Selected Left Group ID:", event.target.value);
        setLeftGroup(event.target.value);
        setMergedData([]);
    };

    const handleChangeRightGroup = (event) => {
        console.log("Selected Right Group ID:", event.target.value);
        setRightGroup(event.target.value);
        setMergedData([]);
    };

    const handleMerge = () => {
        setLoading( true );
        let obj = {
            leftGroup: leftGroup,
            rightGroup: rightGroup,
            mergeType: mergeType,
        };
        console.log("obj: ", obj );
        // Send Request
        GroupService.mergeGroups( obj ).then(
            (response) => {
                console.log("merge response: ", response);
                setMergedData(response.result);
                setLoading( false );
            },
            (error) => {
                console.log("error: ", error);
                setLoading( false );
            }
        );
    };

    const handleCreateGroup = (event) => {
        event.preventDefault();  // Prevent the form from submitting traditionally
        setLoading( true );
        let obj = {
            name: groupName,
            description: groupDescription,
            mergedData: mergedData,
        };
        console.log("obj: ", obj  );
        // return;
        // Send Request
        GroupService.createMergedGroup( obj ).then(
            (response) => {
                console.log("create merged response: ", response );
                setMessage('Group created successfully!');
                setIsError(false);
                setLoading( false );
                setTimeout(() => {
                    setMessage('');
                },3000);
                // Get Groups
                GroupService.getPlantGroups().then(
                    (response) => {
                        // console.log("groups response: ", response);
                        setGroups(response.data.result);
                    },
                    (error) => {
                        console.log("error: ", error);
                    }
                );
            }, 
            ( error ) => {
                console.log('error: ', error );
                setMessage('Failed to create group. Please try again.');
                setIsError(true);
                setLoading( false );
                setTimeout(() => {
                    setMessage('');
                    setIsError(false);
                },3000);
            }
        )
    };

    const updateLeftGroup = () => {
        setLoading( true );
        let obj = {
            plants: mergedData,
            group: leftGroup,
        };
        console.log("obj: ", obj );
        // Send Request
        GroupService.updateMergedGroups(obj).then(
            (response) => {
                console.log("update merge response: ", response);
                setUpdateSuccess( true );
                setLoading( false );
                setTimeout( ()=> {
                    setUpdateSuccess( false );
                },3000);
            },
            (error) => {
                console.log("error: ", error);
                setLoading( false );
            }
        );

    }

    const updateRightGroup = () => {
        setLoading( true );
        let obj = {
            plants: mergedData,
            group: rightGroup,
        };
        console.log("obj: ", obj );
        // Send Request
        GroupService.updateMergedGroups(obj).then(
            (response) => {
                console.log("update merge response: ", response);
                setUpdateSuccess(true);
                setLoading( false );
                setTimeout(() => {
                    setUpdateSuccess(false);
                }, 3000);
            },
            (error) => {
                console.log("error: ", error);
                setLoading( false );
            }
        );
    }

    return (
        <Modal show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>Merge Groups</Modal.Title>
                <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer" }} />
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Typography variant="h5" sx={{ mb: 2 }}>Step 1: Select Groups</Typography>

                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <InputLabel id="select-left-group-label">Left Group</InputLabel>
                        <Select
                            labelId="select-left-group-label"
                            id="select-left-group"
                            value={leftGroup}
                            onChange={(e) => { handleChangeLeftGroup( e ) }}
                        >
                            <MenuItem value="">
                                <em>Select a group</em>
                            </MenuItem>
                            {groups.map((group, index) => (
                                <MenuItem key={index} value={group.Name}>
                                    {group.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <InputLabel id="select-right-group-label">Right Group</InputLabel>
                        <Select
                            labelId="select-right-group-label"
                            id="select-right-group"
                            value={rightGroup}
                            onChange={handleChangeRightGroup}
                        >
                            <MenuItem value="">
                                <em>Select a group</em>
                            </MenuItem>
                            {groups.map((group, index) => (
                                <MenuItem key={index} value={group.Name}>
                                    {group.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Typography variant="h5" sx={{ mb: 2 }}>Step 2: Perform Merge </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 4 }}>
                        <Paper sx={{ p: 2, width: '100%', mb: 2 }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Merge Operation
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Clicking 'Merge' will combine data from both groups using a predefined merge logic,
                                ensuring comprehensive data integration.
                            </Typography>
                            <Button variant="contained" color="primary" onClick={handleMerge}>
                                Merge Groups
                            </Button>
                        </Paper>
                    </Box>
                    {/* <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">Merge Types</FormLabel>
                        <RadioGroup
                            aria-label="merge-type"
                            defaultValue="inner"
                            name="radio-buttons-group"
                            onChange={(e) => setMergeType(e.target.value)}
                        >
                            <Box>
                                <FormControlLabel value="inner" control={<Radio />} label="Inner Join" />
                                <Typography variant="caption" display="block" gutterBottom>
                                    Shows only the plants that are in both groups.
                                </Typography>
                            </Box>
                            <Box>
                                <FormControlLabel value="left" control={<Radio />} label="Left Outer Join" />
                                <Typography variant="caption" display="block" gutterBottom>
                                    Includes all plants from the left group and those that are also in the right group.
                                </Typography>
                            </Box>
                            <Box>
                                <FormControlLabel value="right" control={<Radio />} label="Right Outer Join" />
                                <Typography variant="caption" display="block" gutterBottom>
                                    Includes all plants from the right group and those that are also in the left group.
                                </Typography>
                            </Box>
                            <Box>
                                <FormControlLabel value="full" control={<Radio />} label="Full Outer Join" />
                                <Typography variant="caption" display="block" gutterBottom>
                                    Shows all plants from both groups, regardless of whether they appear in both.
                                </Typography>
                            </Box>
                        </RadioGroup>
                    </FormControl> */}

                    { mergedData.length > 0 && (
                        <>
                        <h5>Merged Group ({mergedData.length} plants):</h5>
                        {/* Table */}
                            <TextField
                                variant="outlined"
                                placeholder="Search by Variety Name or External ID"
                                onChange={handleSearch}
                                style={{ margin: 16, width: "400px" }}
                            />
                            <Table striped hover responsive="sm">
                                <thead>
                                    <tr>
                                        <th>External ID</th>
                                        <th>Variety</th>
                                        <th>PlantCode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(rowsPerPage > 0 ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredData).map((row) => (
                                        <tr key={row.idPlant}>
                                            <td>{row.ExternalID}</td>
                                            <td>{row.Variety}</td>
                                            <td>{row.PlantCode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        {/* Form */}
                        {showForm ? (
                            <>
                                <Form>
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Group Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter group name"
                                            value={groupName}
                                            onChange={e => setGroupName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter a brief description of the group"
                                            value={groupDescription}
                                            onChange={e => setGroupDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                                <Button
                                    variant="contained"
                                    onClick={handleCreateGroup}
                                    startIcon={<AddCircleOutlineIcon />}
                                    style={{marginTop: "10px", marginRight: "5px"}}
                                >
                                    Create New Group
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => { setShowForm( false ) }}
                                    startIcon={ <CloseIcon /> }
                                    style={{marginTop: "10px"}}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h5" sx={{ mb: 2 }}>Step 3: Create & Update Groups </Typography>

                                <div className="w-full"> {/* Full width container */}
                                    <Button 
                                        className="w-full mb-2" 
                                        variant="contained" 
                                        onClick={() => setShowForm(true)} 
                                        style={{marginRight: "5px"}}
                                        startIcon={<AddCircleOutlineIcon />}
                                        >
                                        Create New Group
                                    </Button>
                                    <Tooltip title={leftGroup}>
                                        <Button 
                                            className="w-full mb-2"
                                            variant="outlined"
                                            style={{ marginRight: '5px' }}
                                            startIcon={ <SyncIcon /> }
                                            onClick={ updateLeftGroup } // Add your own handler
                                        >
                                            Update Left Group
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={rightGroup}>
                                        <Button 
                                            className="w-full mb-2"
                                            variant="outlined"
                                            style={{ marginRight: '5px' }}
                                            startIcon={ <SyncIcon /> }
                                            onClick={ updateRightGroup } // Add your own handler
                                        >
                                            Update Right Group
                                        </Button>
                                    </Tooltip>
                                </div>
                            </>
                        )}

                        {message && (
                            <Alert variant={isError ? "danger" : "success"} style={{ marginTop: "10px", marginBottom: "15px" }}>
                                {message}
                            </Alert>
                        )}
                        </>
                    )}


                </Container>
                    { loading && (
                        <>
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </>
                    )}

                    { updateSuccess && (
                        <Alert severity="success">
                            The update was successful!
                        </Alert>
                    )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px" }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MergeModal;