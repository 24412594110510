import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as d3 from 'd3';
import _ from 'underscore';

import ChartService from "../services/charts.service";
import UserService from "../services/user.service";
import SearchPlantsUniversal from "./SearchPlantsUniversal";
import EventBus from "../common/EventBus";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { Table, Pagination } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination from '@mui/material/TablePagination';
import { Typography, Grid, Paper, CircularProgress, Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import almondImage from '../img/almond.png';


// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
const ENDPOINT = socketUrl();
console.log("endpoint: ", ENDPOINT);

const RadarChart = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    let [selectedPlants, setSelectedPlants] = useState([]);
    let [chartData, setChartData] = useState([]);
    let [errorMessage, setErrorMessage] = useState(false);
    let [warningMessage, setWarningMessage] = useState(false);
    let [spinner, setSpinner] = useState(false);
    const d3Src = require("../img/d3.jpg");
    const [redirect, doRedirect] = useState(false);
	let [sample, setSample] = useState("");


    let [searchResults, setSearchResults] = useState([]);
    let [loading, setLoading] = useState(false);
    let [loadingMessage, setLoadingMessage] = useState('');

    function buildChart(dataSets, plants) {
        console.log("dataSets: ", dataSets);
        console.log("plants: ", plants);

        var RadarChart = {
            draw: function (id, d, options) {
                var cfg = {
                    radius: 5,
                    w: 650,
                    h: 600,
                    factor: 1,
                    factorLegend: .85,
                    levels: 3,
                    maxValue: 0,
                    radians: 2 * Math.PI,
                    opacityArea: 0.5,
                    ToRight: 5,
                    TranslateX: 80,
                    TranslateY: 30,
                    ExtraWidthX: 400,
                    ExtraWidthY: 100,
                    color: d3.scale.category10()
                };

                if ('undefined' !== typeof options) {
                    for (var i in options) {
                        if ('undefined' !== typeof options[i]) {
                            cfg[i] = options[i];
                        }
                    }
                }
                cfg.maxValue = Math.max(cfg.maxValue, d3.max(d, function (i) { return d3.max(i.map(function (o) { return o.value; })) }));
                var allAxis = (d[0].map(function (i, j) { return i.axis }));
                var total = allAxis.length;
                var radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2);
                var Format = d3.format('%');
                d3.select(id).select("svg").remove();

                var g = d3.select(id)
                    .append("svg")
                    .attr("width", cfg.w + cfg.ExtraWidthX)
                    .attr("height", cfg.h + cfg.ExtraWidthY)
                    .append("g")
                    .attr("transform", "translate(" + cfg.TranslateX + "," + cfg.TranslateY + ")");
                ;

                var tooltip;

                //Circular segments
                for (var j = 0; j < cfg.levels - 1; j++) {
                    var levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
                    g.selectAll(".levels")
                        .data(allAxis)
                        .enter()
                        .append("svg:line")
                        .attr("x1", function (d, i) { return levelFactor * (1 - cfg.factor * Math.sin(i * cfg.radians / total)); })
                        .attr("y1", function (d, i) { return levelFactor * (1 - cfg.factor * Math.cos(i * cfg.radians / total)); })
                        .attr("x2", function (d, i) { return levelFactor * (1 - cfg.factor * Math.sin((i + 1) * cfg.radians / total)); })
                        .attr("y2", function (d, i) { return levelFactor * (1 - cfg.factor * Math.cos((i + 1) * cfg.radians / total)); })
                        .attr("class", "line")
                        .style("stroke", "grey")
                        .style("stroke-opacity", "0.75")
                        .style("stroke-width", "0.3px")
                        .attr("transform", "translate(" + (cfg.w / 2 - levelFactor) + ", " + (cfg.h / 2 - levelFactor) + ")");
                }

                //Text indicating at what % each level is
                for (var j = 0; j < cfg.levels; j++) {
                    var levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
                    g.selectAll(".levels")
                        .data([1]) //dummy data
                        .enter()
                        .append("svg:text")
                        .attr("x", function (d) { return levelFactor * (1 - cfg.factor * Math.sin(0)); })
                        .attr("y", function (d) { return levelFactor * (1 - cfg.factor * Math.cos(0)); })
                        .attr("class", "legend")
                        .style("font-family", "sans-serif")
                        .style("font-size", "10px")
                        .style("cursor", "pointer")
                        .attr("transform", "translate(" + (cfg.w / 2 - levelFactor + cfg.ToRight) + ", " + (cfg.h / 2 - levelFactor) + ")")
                        .attr("fill", "#737373")
                        .text(Format((j + 1) * cfg.maxValue / cfg.levels));
                }

                var series = 0;

                var axis = g.selectAll(".axis")
                    .data(allAxis)
                    .enter()
                    .append("g")
                    .attr("class", "axis");

                axis.append("line")
                    .attr("x1", cfg.w / 2)
                    .attr("y1", cfg.h / 2)
                    .attr("x2", function (d, i) { return cfg.w / 2 * (1 - cfg.factor * Math.sin(i * cfg.radians / total)); })
                    .attr("y2", function (d, i) { return cfg.h / 2 * (1 - cfg.factor * Math.cos(i * cfg.radians / total)); })
                    .attr("class", "line")
                    .style("stroke", "grey")
                    .style("stroke-width", "1px");

                axis.append("text")
                    .attr("class", "legend")
                    .text(function (d) { return d })
                    .style("font-family", "sans-serif")
                    .style("font-size", "11px")
                    .attr("text-anchor", "middle")
                    .attr("dy", "1.5em")
                    .attr("transform", function (d, i) { return "translate(0, -10)" })
                    .attr("x", function (d, i) { return cfg.w / 2 * (1 - cfg.factorLegend * Math.sin(i * cfg.radians / total)) - 60 * Math.sin(i * cfg.radians / total); })
                    .attr("y", function (d, i) { return cfg.h / 2 * (1 - Math.cos(i * cfg.radians / total)) - 20 * Math.cos(i * cfg.radians / total); });

                d.forEach(function (dataset, series) {
                    var dataValues = [];
                    g.selectAll(".nodes")
                        .data(dataset, function (j, i) {
                            dataValues.push([
                                cfg.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.sin(i * cfg.radians / total)),
                                cfg.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.cos(i * cfg.radians / total))
                            ]);
                        });
                    dataValues.push(dataValues[0]);
                    g.selectAll(".area")
                        .data([dataValues])
                        .enter()
                        .append("polygon")
                        .attr("class", "radar-chart-serie" + series)
                        .style("stroke-width", "2px")
                        .style("stroke", cfg.color(series))
                        .attr("points", function (d) {
                            var str = "";
                            for (var pti = 0; pti < d.length; pti++) {
                                str = str + d[pti][0] + "," + d[pti][1] + " ";
                            }
                            return str;
                        })
                        .style("fill", function (j, i) { return cfg.color(series) })
                        .style("fill-opacity", cfg.opacityArea)
                        .on('mouseover', function (d) {
                            var z = "polygon." + d3.select(this).attr("class");
                            g.selectAll("polygon")
                                .transition(200)
                                .style("fill-opacity", 0.1);
                            g.selectAll(z)
                                .transition(200)
                                .style("fill-opacity", .7);
                        })
                        .on('mouseout', function () {
                            g.selectAll("polygon")
                                .transition(200)
                                .style("fill-opacity", cfg.opacityArea);
                        });

                    g.selectAll(".nodes")
                        .data(dataset).enter()
                        .append("svg:circle")
                        .attr("class", "radar-chart-serie" + series)
                        .attr('r', cfg.radius)
                        .attr("alt", function (j) { return Math.max(j.value, 0) })
                        .attr("cx", function (j, i) {
                            return cfg.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.sin(i * cfg.radians / total));
                        })
                        .attr("cy", function (j, i) {
                            return cfg.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) * cfg.factor * Math.cos(i * cfg.radians / total));
                        })
                        .attr("data-id", function (j) { return j.axis })
                        .style("fill", cfg.color(series)).style("fill-opacity", .9)
                        .on('mouseover', function (d) {
                            var newX = parseFloat(d3.select(this).attr('cx')) - 10;
                            var newY = parseFloat(d3.select(this).attr('cy')) - 5;

                            tooltip
                                .attr('x', newX)
                                .attr('y', newY)
                                .text(Format(d.value))
                                .transition(200)
                                .style('opacity', 1);

                            var z = "polygon." + d3.select(this).attr("class");
                            g.selectAll("polygon")
                                .transition(200)
                                .style("fill-opacity", 0.1);
                            g.selectAll(z)
                                .transition(200)
                                .style("fill-opacity", .7);
                        })
                        .on('mouseout', function () {
                            tooltip
                                .transition(200)
                                .style('opacity', 0);
                            g.selectAll("polygon")
                                .transition(200)
                                .style("fill-opacity", cfg.opacityArea);
                        })
                        .append("svg:title")
                        .text(function (j) { return Math.max(j.value, 0) });

                    series++;
                });

                //Tooltip
                tooltip = g.append('text')
                    .style('opacity', 0)
                    .style('font-family', 'sans-serif')
                    .style('font-size', '13px');
            }
        };

        var w = 550, h = 550;

        var colorscale = d3.scale.category10();

        //Legend titles
        var LegendOptions = plants;

        // Data
        var d = dataSets;

        //Options for the Radar chart, other than default
        var mycfg = {
            w: w,
            h: h,
            maxValue: 1,
            levels: 5,
            ExtraWidthX: 200
        }

        //Call function to draw the Radar chart
        //Will expect that data is in %'s
        RadarChart.draw("#chart", d, mycfg);

        ////////////////////////////////////////////
        /////////// Initiate legend ////////////////
        ////////////////////////////////////////////

        var svg = d3.select('#chart-body')
            .selectAll('svg')
            .append('svg')
            .attr("width", w + 300)
            .attr("height", h)

        //Create the title for the legend
        var text = svg.append("text")
            .attr("class", "title")
            .attr('transform', 'translate(90,0)')
            .attr("x", w - 70)
            .attr("y", 10)
            .attr("font-size", "12px")
            .attr("fill", "#404040")
            .text("Phenotype Values of Almonds");

        //Initiate Legend    
        var legend = svg.append("g")
            .attr("class", "legend")
            .attr("height", 100)
            .attr("width", 200)
            .attr('transform', 'translate(90,20)')
            ;
        //Create colour squares
        legend.selectAll('rect')
            .data(LegendOptions)
            .enter()
            .append("rect")
            .attr("x", w - 65)
            .attr("y", function (d, i) { return i * 20; })
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", function (d, i) { return colorscale(i); })
            ;
        //Create text next to squares
        legend.selectAll('text')
            .data(LegendOptions)
            .enter()
            .append("text")
            .attr("x", w - 52)
            .attr("y", function (d, i) { return i * 20 + 9; })
            .attr("font-size", "11px")
            .attr("fill", "#737373")
            .text(function (d) { return d; })
            ;
    }

    function handleChange(event) {
        // console.log('event: ', event.target.value );
        var value = event.target.value;
        setSample(value);
    }

    function handleClick(event) {
        console.log('sample: ', sample);
        // return;
        setLoading(true);
        setLoadingMessage(true);
        /* Send Request */
        ChartService.buildChart(sample).then(
            (response) => {
                console.log("chart response: ", response);
                setSample("");
                setLoading(false);
                if (response.result.length === 0) {
                    // No Phenos for this plant 
                    setLoadingMessage(false);
                    setWarningMessage(true);
                    setTimeout(function () {
                        setWarningMessage(false);
                    }, 3000);
                } else {
                    // Found Phenos
                    setChartData(prevData => [...prevData, response.result]);
                    setSelectedPlants(prevPlants => [...prevPlants, response.plant]);
                    setLoadingMessage(false);
                    buildChart([...chartData, response.result], [...selectedPlants, response.plant]);
                }
            },
            (error) => {
                console.log("error: ", error);
                setSample("");
                setLoading(false);
                setErrorMessage(true);
                setLoadingMessage(false);
                setChartData([]);
                setTimeout(function () {
                    setErrorMessage(false);
                }, 3000);
            }
        );
    }

    const handleRowClick = (plantCode) => {
        console.log('Row Clicked, plantcode: ', plantCode);
        setSample(plantCode);
    };

    const handleClearChartData = () => {
        setChartData([]);
        setSelectedPlants([]);
    };

    useEffect(() => {
        if (sample) {
            handleClick();
        }
    }, [sample]);

    // Pagination for the plants table
    const [plantsPage, setPlantsPage] = useState(0);
    const [plantsRowsPerPage, setPlantsRowsPerPage] = useState(10);

    // Pagination for the search results table
    const [searchResultsPage, setSearchResultsPage] = useState(0);
    const [searchResultsRowsPerPage, setSearchResultsRowsPerPage] = useState(10);

    // Handling change of page for plants table
    const handlePlantsChangePage = (event, newPage) => {
        setPlantsPage(newPage);
    };

    // Handling change in rows per page for plants table
    const handlePlantsChangeRowsPerPage = (event) => {
        setPlantsRowsPerPage(parseInt(event.target.value, 10));
        setPlantsPage(0); // Reset page to 0 on rows per page change
    };

    // Handling change of page for search results table
    const handleSearchResultsChangePage = (event, newPage) => {
        setSearchResultsPage(newPage);
    };

    // Handling change in rows per page for search results table
    const handleSearchResultsChangeRowsPerPage = (event) => {
        setSearchResultsRowsPerPage(parseInt(event.target.value, 10));
        setSearchResultsPage(0); // Reset page to 0 on rows per page change
    };

    // Calculate the current data slice for search results table
    const currentSearchResultsPageData = searchResults.slice(searchResultsPage * searchResultsRowsPerPage, searchResultsPage * searchResultsRowsPerPage + searchResultsRowsPerPage);

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        console.log("socket: ", socket);

        /** Search Results */
        socket.on("pedigree-search", response => {
            console.log("socket.io results: ", response);
            if (currentUser.id === response.user.id) {
                if (response.status === 0) {
                    var results = [];
                    _.each(response.result, function (value) {
                        // console.log("value: ", value );
                        var obj = {
                            idPlant: value.idPlant,
                            PlantCode: value.PlantCode,
                            AlternatePlantID: value.AlternatePlantID,
                            'Verinomics Code': value.PlantCode,
                            'Company Code': value.AlternatePlantID,
                            CrossCode: value.CrossCode,
                            AlternateCrossID: value.AlternateCrossID,
                            ExternalID: value.AlternatePlantID,
                        };
                        results.push(obj);
                    });

                    setSearchResults(results);
                    setLoading(false);
                    setLoadingMessage(false);
                } else if (response.status === 2) {
                    console.warn("no plants...");
                    // No Plants Found 
                    setTimeout(function () {
                        setLoading(false);
                        setLoadingMessage(false);
                    }, 3000);
                } else {
                    console.warn("something went wrong");
                    setLoading(false);
                    setLoadingMessage(false);
                }
            }
        });

        // Check User
        UserService.getUserBoard().then(
            (response) => {
                console.log("user response: ", response);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("error: ", _content);
                doRedirect(true);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );

    }, []);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="container">
            <header className="jumbotron">
                <img className="float-right" src={d3Src.default} alt="openaia-logo" height={50} style={{ marginRight: "5px" }} />

                <h3> Almond Phenotype Radar Chart </h3>
                <small style={{ fontSize: "12px" }}>built with  <a href="https://d3js.org/">d3.js</a></small>
            </header>
            {spinner &&
                <div className="almond-spinner">
                    <img src={almondImage} alt="Almond Spinner" />
                </div>
            }

            {/* Radar Chart */}
            {chartData.length !== 0 && (
                <div className="row" >
                    <div className="container">
                        <Paper elevation={3} sx={{ p: 4, marginBottom: "25px", flexGrow: 1 }}>
                            <div className="text-center" id="chart-body">
                                <div id="chart"></div>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '15px' }}>
                                <Button variant="contained" color="primary" onClick={handleClearChartData}>
                                    Clear Results
                                </Button>
                            </div>
                        </Paper>
                    </div>
                </div>
            )}

            <div className="row" id="chart-body">
                <div className="col-md-6">
                    <SearchPlantsUniversal
                        setSpinner={setLoading}
                        loading={loading}
                        setLoading={setLoading}
                        setSearchResults={setSearchResults}
                        setLoadingMessage={setLoadingMessage}
                    />
                    {/* Plant Code */}
                    {/* <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-basic"
                                label="Scan Plant Code"
                                variant="outlined"
                                value={sample}
                                onChange={handleChange}
                                onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); handleClick() } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" color="primary" onClick={handleClick}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Box> */}

                </div>
                <div className="col-md-6">
                    <Paper elevation={3} sx={{ p: 4, marginBottom: "15px", flexGrow: 1 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Search Results</Typography>
                        {searchResults.length === 0 && (
                            <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
                                Radar charts, also known as spider charts, are graphical representations that display multivariate data in a two-dimensional chart of three or more quantitative variables represented on axes starting from the same point. They are useful for comparing multiple variables visually on a single chart, making it easy to see patterns, strengths, and weaknesses.
                                <br /><br />
                                For visualizing almond phenotypes, radar charts are a powerful tool. They can represent various traits such as size, shape, yield, flavor, and disease resistance. Each trait is displayed as a spoke on the chart, with values for each phenotype plotted along these spokes. By connecting the points, a unique shape is formed that represents the overall profile of the phenotype. This makes it easy to compare different almond phenotypes and identify unique or desirable traits at a glance.
                            </Alert>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                <b>Server Response:</b> No plants found with the selected search terms.
                            </div>
                        )}
                        {warningMessage && (
                            <div className="alert alert-warning" role="alert">
                                <b>Server Response:</b> No phenotypes found for that plant, try again.
                            </div>
                        )}
                        {loadingMessage && (
                            <div className="alert alert-info" role="alert">
                                Fetching search results. This may take 1-2 minutes for some queries. Thank you for your patience.
                            </div>
                        )}
                        {searchResults.length > 0 && (
                            <>
                                <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
                                    Select a plant to create a Radar Chart.
                                </Alert>
                                <Table striped hover responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Verinomics Code</th>
                                            <th>External ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentSearchResultsPageData.map(plant => (
                                            <tr key={plant.idPlant} onClick={() => handleRowClick(plant.PlantCode)} style={{ cursor: "pointer" }}>
                                                <td style={{ padding: "15px" }}>{plant.PlantCode}</td>
                                                <td style={{ padding: "15px" }}> {plant.AlternatePlantID ?? plant.ExternalID} </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={searchResults.length}
                                    rowsPerPage={searchResultsRowsPerPage}
                                    page={searchResultsPage}
                                    onPageChange={handleSearchResultsChangePage}
                                    onRowsPerPageChange={handleSearchResultsChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Paper>
                </div>

            </div>


        </div>

    );
};

export default RadarChart;

