import axios from "axios";
import authHeader from "./auth-header";

import URL from '../common/GetUrl';
var url = URL();
const API_URL = url;
// console.log("url: ", url );

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const changePassword = (body) => {
  // console.log("from service: ", body );
  return axios
      .post(API_URL + "changePassword", { body }, { headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};

const feedback = (body) => {
  // console.log("from service: ", body );
  return axios
      .post(API_URL + "feedback", { body }, { headers: authHeader() })
      .then((response) => {
          return response.data;
      });
};


const userService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  changePassword,
  feedback,
};

export default userService