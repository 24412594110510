import React, { useState } from "react";
import _ from "underscore";
import PlantsService from "../services/plants.service";
import GroupsService from "../services/groups.service";
import SummaryModal from './SummaryModal';

// Data Table 
import DataTable from "react-data-table-component";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

// Filter DataTable
const TextFieldSearch = styled.input` height: 32px; width: 200px; border-radius: 3px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-top-right-radius: 0; border-bottom-right-radius: 0; border: 1px solid #e5e5e5; padding: 0 32px 0 16px; &:hover { cursor: pointer; } `;
const ClearButton = styled(Button)` border-top-left-radius: 0; border-bottom-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px; height: 34px; width: 32px; text-align: center; display: flex; align-items: center; justify-content: center; `;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextFieldSearch
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton className="btn btn-primary" type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const sortIcon = <ArrowDownward />;

const GroupTable = (props) => {
    // console.log("table props: ", props );
    let [showModal, setShowModal] = useState(false);
    let [plantSummary, setPlantSummary] = useState([]);
    let [plantPhenotypes, setPlantPhenotypes] = useState([]);
    let [plantDescriptors, setPlantDescriptors] = useState([]);
    let [maternalCrosses, setMaternalCrosses ] = useState([]);
    let [paternalCrosses, setPaternalCrosses ] = useState([]);

    // Handle Clicks
    let moreInfoClick = (state) => {
        console.log('clicked: ', state);
        props.setSpinner(true)
        var obj = {
            code: state.PlantCode,
            company: "Burchell"
        }
        console.log("obj: ", obj);
        // return;
        // Send Request
        PlantsService.getPlantSummary(obj).then(
            (response) => {
                console.log("plant response: ", response);

                var arr = [];
                _.each(Object.keys(response.result), function (value) {
                    if (typeof response.result[value] == "string") {
                        var obj = {
                            key: value,
                            data: response.result[value]
                        }
                        arr.push(obj);
                    }
                });
                // console.log("arr: ", arr);
                setMaternalCrosses( response.result.MaternalCrosses );
                setPaternalCrosses( response.result.PaternalCrosses );
                setPlantPhenotypes( response.result.Phenotypes );
                setPlantDescriptors( response.result.Descriptors );
                setPlantSummary(arr);
                setShowModal(true);
                props.setSpinner(false)
            },
            (error) => {
                console.log("error: ", error);
                props.setSpinner(false)
            }
        );

    };

    // let removeClick = (state, index) => {
    //     console.log("state: ", state );
    //     props.setSpinner( true );
    //     // console.log("i: ", index );
        
    //     // Send Request 
    //     GroupsService.removePlantFromGroup( state, ).then(
    //         (response) => {
    //             console.log("remove response: ", response );
    //             setTimeout(function() {
    //                 // assigning the list to temp variable
    //                 const temp = [...props.groupResults];
    //                 // console.log("before temp: ", temp );
    //                 // removing the element using splice
    //                 temp.splice(index, 1);
    //                 // updating the list
    //                 // console.log("after: ", temp );
    //                 props.setGroupResults( temp );
    //                 // Spinner 
    //                 props.setSpinner( false );
    //             },500);
    //         }, 
    //         (error) => {
    //             console.log("error: ", error );
    //             props.setSpinner( false );
    //         }
    //     ) 

    // }

    const columns = [
        {
            name: 'Company Code',
            selector: row => row.ExternalID,
            sortable: true,
        },
        {
            name: 'Variety',
            selector: row => row.Variety,
            sortable: true,
        },
        {
            name: 'Verinomics Code',
            selector: row => row.PlantCode,
            sortable: true,
        },
        {
            name: "More Info",
            cell: (row) => <IconButton aria-label="info" onClick={() => moreInfoClick(row )} id={row.ID}> <InfoIcon /> </IconButton>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        // {
        //     name: "Remove",
        //     cell: (row, index) => <IconButton aria-label="delete" onClick={() => removeClick(row, index )} id={row.ID}> <DeleteIcon /> </IconButton>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

    // Filter
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = props.groupResults.filter(
        item => item.ExternalID && item.ExternalID.toLowerCase().includes(filterText.toLowerCase()),
    );
    // console.log("filtered: ", filteredItems );
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
       <>
        <DataTable
            sortIcon={sortIcon}
            columns={columns}
            data={filteredItems}
            onColumnOrderChange={cols => console.log(cols)}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            highlightOnHover
            pagination
            responsive
            striped
        />

        <SummaryModal
            show={showModal}
            setShow={setShowModal}
            plantSummary={plantSummary}
            plantPhenotypes={plantPhenotypes}
            plantDescriptors={plantDescriptors}
            maternalCrosses={maternalCrosses}
            paternalCrosses={paternalCrosses}
        />

       </>
    );
};

export default GroupTable;
