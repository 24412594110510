import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from 'react-router-dom'; // version 5.2.0
import _ from "underscore";
// XLSX
import * as XLSX from 'xlsx/xlsx.mjs';
// Services
import GroupService from "../services/groups.service";
import PlantsService from "../services/plants.service";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

// Components
import SummaryModal from './SummaryModal';
import SearchForm from "./SearchForm";
import PhenotypeModal from "./PhenotypeModal";
import PhenotypeTable from "./PhenotypeTable";
import BarcodeScan from "./BarcodeScan";
import PlantsGroups from "./PlantsGroups";
import NotesModal from "./NotesModal";
import ClonesModal from "./ClonesModal";
import PhenotypeSearch from "./PhenotypeSearch";
import SearchPhenotype from "./SearchPhenotype";

// Data Table 
import DataTable from "react-data-table-component";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from "react-bootstrap/Button";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import InfoIcon from '@mui/icons-material/Info';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import StorageIcon from '@mui/icons-material/Storage';

import almondImage from '../img/almond.png';

// Socket.io
import socketIOClient from 'socket.io-client';
import socketUrl from '../common/GetSocket';
import { Redirect } from "react-router-dom";
const ENDPOINT = socketUrl();
console.log("endpoint: ", ENDPOINT );


// Filter DataTable
const TextFieldSearch = styled.input` height: 32px; width: 200px; border-radius: 3px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-top-right-radius: 0; border-bottom-right-radius: 0; border: 1px solid #e5e5e5; padding: 0 32px 0 16px; &:hover { cursor: pointer; } `;
const ClearButton = styled(Button)` border-top-left-radius: 0; border-bottom-left-radius: 0; border-top-right-radius: 5px; border-bottom-right-radius: 5px; height: 34px; width: 32px; text-align: center; display: flex; align-items: center; justify-content: center; `;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextFieldSearch
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const sortIcon = <ArrowDownward />;

const PlantSearch = () => {
    let [searchResults, setSearchResults] = useState([]);
    let [command, setCommand] = useState("");
    let [spinner, setSpinner] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");
    let [loadingMessage, setLoadingMessage] = useState("");
    let [plantSummary, setPlantSummary] = useState([]);
    let [clones, setClones ] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [showClonesModal, setShowClonesModal] = useState(false);
    let [showPhenotypeModal, setShowPhenotypeModal] = useState(false);
    let [showNotesModal, setShowNotesModal] = useState(false);
    let [showCommand, setShowCommand] = useState(false);
    let [currentPlant, setCurrentPlant ] = useState({});
    let [selection, setSelection] = useState('');
    let [phenotypes, setPhenotypes] = useState([]);
    let [phenotypeResults, setPhenotypeResults] = useState([]);
    let [plantPhenotypes, setPlantPhenotypes] = useState([]);
    let [plantDescriptors, setPlantDescriptors] = useState([]);
    let [showPhenotypes, setShowPhenotypes ] = useState(false);
    let [hideLocation, setHideLocation] = useState(true);
    let [showPlantsGroups, setShowPlantsGroups ] = useState( false );
    let [maternalCrosses, setMaternalCrosses ] = useState([]);
    let [paternalCrosses, setPaternalCrosses ] = useState([]);
    const [redirect, doRedirect] = useState(false)

    let clearResults = () => {
        setSearchResults([]);
        setCommand("");
    }

    let handleGetInfo = (state) => {
        setSpinner(true)
        console.log('clicked: ', state);
        var obj = {
            code: state['Verinomics Code'],
            company: "Burchell"
        }
        console.log("obj: ", obj);
        PlantsService.getPlantSummary(obj).then(
            (response) => {
                console.log("plant response: ", response);

                var arr = [];
                _.each(Object.keys(response.result), function (value) {
                    if (typeof response.result[value] == "string") {
                        var obj = {
                            key: value,
                            data: response.result[value]
                        }
                        arr.push(obj);
                    }
                });
                // console.log("arr: ", arr);
                setPaternalCrosses( response.result.PaternalCrosses );
                setMaternalCrosses( response.result.MaternalCrosses );
                setPlantPhenotypes( response.result.Phenotypes );
                setPlantDescriptors( response.result.Descriptors );
                setPlantSummary(arr);
                setShowModal(true);
                setSpinner(false)
            },
            (error) => {
                console.log("error: ", error);
                setSpinner(false)
            }
        );

    };

    let handleAddNotes = (state) => {
        setSpinner(true)
        console.log('clicked: ', state);
        var obj = {
            code: state['Verinomics Code'],
            company: "Burchell"
        }
        console.log("obj: ", obj);
        setCurrentPlant( state );
        setShowNotesModal( true );
        setSpinner(false);
    }

    let handleFindClones = ( state ) => {
        setSpinner(true);
        console.log("state: ", state );
        var obj = {
            idPlant: state.idPlant,
            company: "Burchell"
        };
        // Send Request
        PlantsService.findClones( obj ).then(
            (response) => {
                console.log("clones response: ", response );
            },
            (error) => {
                console.log("error: ", error );
                setSpinner(false);
            }
        )
    }

    let clickShowCommand = () => {
        console.log("clicked..");
        setShowCommand(!showCommand);
    }

    /** Dropdown */
    let handleChange = (event) => {
        // console.log("event: ", event.target.value );
        setSelection( event.target.value ) 
        switch (event.target.value) {
            case 'phenotypes':
                console.log("phenos");
                getAvailablePhenotypes();
                setTimeout(function() {
                    setSelection("");
                },500);
                break;
            case 'download':
                console.log("download");
                downloadResults();
                setTimeout(function() {
                    setSelection("");
                },500);
                break;
                case 'locations':
                console.log("locs");
                findLocations();
                setTimeout(function() {
                    setSelection("");
                },500);
                break;
            case 'add':
                console.log("add");
                setShowPlantsGroups( true );
                setTimeout(function() {
                    setSelection("");
                },500);
                break;
            case 'clear':
                console.log("clear");
                clearResults();
                setTimeout(function() {
                    setSelection("");
                },500);
                break;
            default:
                console.log("something went wrong..");
        }

    };

    let getAvailablePhenotypes = () => {
        console.log("get phenos..");
        setSpinner(true);
        var ids = _.pluck(searchResults, 'idPlant');
        var obj = {
            idCompany: 1,
            company: "Burchell",
            ids: ids.join(",")
        };
        console.log("obj: ", obj);
        // Send Request
        GroupService.getAvailablePhenotypes(obj).then(
            (response) => {
                // Success
                console.log("pheno response: ", response);
                console.log("found phenotypes...");

                var phenos = [];
                _.each(response.result.Phenotypes, function (value) {
                    var obj = { value: value }
                    phenos.push( obj );
                });
                phenos.push({value:"Check All"});
                setPhenotypes(phenos);
                setShowPhenotypeModal(true);
                setSpinner(false);
            },
            (error) => {
                // Error
                console.log("something went wrong..");
                setSpinner(false);
            }
        )
    }

    let downloadResults = () => {
        setSpinner(true)
        console.log("download search results.");

        let results = [];
        _.each( filteredItems, function(value) {
            // console.log("value: ", value );
            var obj = {
                idPlant: value.idPlant,
                PlantCode: value.PlantCode,
                AlternatePlantID: value.externalID,
                'Verinomics Code': value.PlantCode,
                'Company Code': value.externalID
            };
            results.push( obj );
        });
        console.log("search results: ", results );

        /* generate a worksheet */
        var ws = XLSX.utils.json_to_sheet( results );

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, "Verinomics");

        /* write workbook and force a download */
        XLSX.writeFile( wb, "SearchResults.xlsx");
        // Loading
        setTimeout(function() {
            setSpinner(false)
        },500);
    }

    let findLocations = () => {
        setSpinner(true);
        var ids = _.pluck( searchResults, 'idPlant');
        var obj = {
            ids: ids.join(","),
            idCompany: 1,
            company: "Burchell"
        };
        console.log("obj: ", obj );
        PlantsService.findLocations( obj ).then(
            ( response ) => {
                console.log("loc response: ", response );

                var obj = {
                    name: 'Location',
                    selector: row => row.Location,
                    sortable: true,
                };

                columns.push( obj );
                console.log('cols: ', columns );

                var results = [];
                _.each(response.result, function (value) {
                    // console.log("value: ", value );
                    var obj = {
                        idPlant: value.idPlant,
                        PlantCode: value.PlantCode,
                        AlternatePlantID: value.AlternatePlantID,
                        'Verinomics Code': value.PlantCode,
                        'Company Code': value.AlternatePlantID,
                        CrossCode: value.CrossCode,
                        AlternateCrossID: value.AlternateCrossID,
                        Location: value.Location,
                    };
                    results.push(obj);
                });

                setSearchResults( results );
                setSpinner( false );
                
                setHideLocation( !hideLocation );

            },
            ( error ) => {
                console.log("error: ", error );
                setSpinner( false );
            }
        )


    }

    const columns = useMemo(
        () => [
            {
                name: 'Company Code',
                selector: row => row['Company Code'],
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: 'Verinomics Code',
                selector: row => row['Verinomics Code'],
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: 'Cross Code',
                selector: row => row.CrossCode,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: 'AlternateCrossID',
                selector: row => row.AlternateCrossID,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: 'Location',
                selector: row => row.Location,
                sortable: true,
                grow: 2,
                omit: hideLocation,
            },
            {
                name: "More Info",
                cell: (row) => <button className="btn btn-sm btn-primary btn-block" style={{margin: "5px"}} onClick={() => handleGetInfo(row)} id={row.ID}> <InfoIcon /> </button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                name: "Add Notes",
                cell: (row) => <button className="btn btn-sm btn-info btn-block" style={{margin: "5px"}} onClick={() => handleAddNotes(row)} id={row.ID}> <SpeakerNotesIcon /> </button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                name: "Find Clones",
                cell: (row) => <button className="btn btn-sm btn-secondary btn-block" style={{margin: "5px"}} onClick={() => handleFindClones(row)} id={row.ID}> <SearchIcon /> </button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ],
        [hideLocation],
    );

    // Filter
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = searchResults.filter(
        item => item['Company Code'] && item['Company Code'].toLowerCase().includes(filterText.toLowerCase()),
    );
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const { user: currentUser } = useSelector((state) => state.auth);
    // console.log("current user: ", currentUser );

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        console.log("socket: ", socket);
        
        /** Search Results */
        socket.on("pedigree-search", response => {
            console.log("socket.io results: ", response);
            if( currentUser.id === response.user.id ) {
                if (response.status === 0) {
                    setLoadingMessage("");
                    var results = [];
                    _.each(response.result, function (value) {
                        // console.log("value: ", value );
                        var obj = {
                            idPlant: value.idPlant,
                            PlantCode: value.PlantCode,
                            AlternatePlantID: value.AlternatePlantID,
                            'Verinomics Code': value.PlantCode,
                            'Company Code': value.AlternatePlantID,
                            CrossCode: value.CrossCode,
                            AlternateCrossID: value.AlternateCrossID
                        };
                        results.push(obj);
                    });
    
                    setSearchResults(results);
                    setSpinner(false);
                    setCommand(response.cmd);
                } else if (response.status === 2) {
                    console.warn("no plants...");
                    // No Plants Found 
                    setLoadingMessage("");
                    setErrorMessage("No plants found in the database.");
                    setTimeout(function () {
                        setErrorMessage("");
                        setSpinner(false)
                    }, 3000);
                } else {
                   setLoadingMessage("");
                   console.warn("something went wrong");
                   setSpinner(false)
                }
            } 
        });

        socket.on("clones", response => {
            // console.log("socket.io results: ", response);
            if( currentUser.id === response.user.id ) {
                setClones( response.result );
                setShowClonesModal( true );
                setSpinner(false);
            }
        });

        UserService.getUserBoard().then(
            (response) => {
                // console.log("user response: ", response);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("error: ", _content);
                doRedirect( true );
                
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );

    }, [currentUser]);

    return (
        <>
        <div className="container">
            <header className="jumbotron">
                <h3> Plant Search </h3>
                <p>Search our database for plants and phenotypes.</p>
            </header>
            <div className="row">
                { redirect && <Redirect to="/"/> }
                {spinner &&
                    <div className="almond-spinner">
                        <img src={almondImage} alt="Almond Spinner" />
                    </div>
                }
                {filteredItems.length !== 0 ?
                    (
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">

                                    <Box className="float-right" sx={{ minWidth: 140 }}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="Options-Label"> <SettingsIcon /> Options</InputLabel>
                                            <Select
                                                labelId="Options-Label"
                                                id="demo-simple-select"
                                                value={selection}
                                                label=""
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em style={{color: "gray"}}>Select Option</em>
                                                </MenuItem>
                                                <MenuItem value={"phenotypes"}> <SearchIcon style={{marginRight: "5px"}}/> Get Available Phenotypes</MenuItem>
                                                <MenuItem value={"download"}> <DownloadIcon style={{marginRight: "5px"}}/> Download Results</MenuItem>
                                                <MenuItem value={"locations"}> <SearchIcon style={{marginRight: "5px"}}/> Find Locations</MenuItem>
                                                <MenuItem value={"add"}> <AddCircleIcon style={{marginRight: "5px"}}/> Add Plants to a Group</MenuItem>
                                                <MenuItem value={"clear"}> <HighlightOffIcon style={{marginRight: "5px"}}/> Clear Search Results</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <div className="card-title"> Search Results - {searchResults.length} Plants</div>
                                </div>
                                <div className="card-body">
                                    {searchResults &&
                                        <DataTable
                                            sortIcon={sortIcon}
                                            columns={columns}
                                            data={filteredItems}
                                            onColumnOrderChange={cols => console.log(cols)}
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemo}
                                            highlightOnHover
                                            pagination
                                            responsive
                                            striped
                                        />
                                    }
                                </div>

                                {/* <div className="card-footer">
                                    {showCommand === true ?
                                        (
                                            <>
                                                <div className="alert alert-info" role="alert">
                                                    <b>Java Command:</b>  {command}
                                                </div>
                                                <button className="btn btn-sm btn-secondary float-right" onClick={clickShowCommand}> Hide Java Command </button>
                                            </>
                                        ) : (
                                            <button className="btn btn-sm btn-secondary" onClick={clickShowCommand}> Show Java Command </button>
                                        )
                                    }

                                </div> */}

                            </div>
                        </div>
                    ) : (
                        <>
                        {/* Search Form's */}
                            <div className="col-md-12">
                                {errorMessage !== "" && (
                                    <div className="alert alert-danger" role="alert">
                                        <b> <StorageIcon /> Server Response:</b>  {errorMessage}
                                    </div>
                                )}

                                {loadingMessage !== "" && (
                                    <div className="alert alert-warning" role="alert">
                                        <b> <RotateRightIcon /> Loading:</b>  {loadingMessage}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <SearchForm setSpinner={setSpinner} />

                                <BarcodeScan 
                                    setSpinner={setSpinner}
                                    setCommand={setCommand}
                                    setSearchResults={setSearchResults} 
                                    setErrorMessage={setErrorMessage} 
                                />
                            </div>
                            <div className="col-md-6">
                                {/* <PhenotypeSearch 
                                    setSpinner={setSpinner}
                                    setLoadingMessage={setLoadingMessage}
                                /> */}
                                <SearchPhenotype 
                                    setLoading={setSpinner}
                                    setSearchResults={setSearchResults} 
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <SummaryModal
                show={showModal}
                setShow={setShowModal}
                setSpinner={setSpinner}
                plantSummary={plantSummary}
                plantPhenotypes={plantPhenotypes}
                plantDescriptors={plantDescriptors}
                maternalCrosses={maternalCrosses}
                paternalCrosses={paternalCrosses}
            />
            <PhenotypeModal
                show={showPhenotypeModal}
                setShow={setShowPhenotypeModal}
                setSpinner={setSpinner}
                phenotypes={phenotypes}
                searchResults={searchResults}
                setSelection={setSelection}
                setShowPhenotypes={setShowPhenotypes}
                setPhenotypeResults={setPhenotypeResults}

            />
            <PhenotypeTable
                show={showPhenotypes}
                setShow={setShowPhenotypes}
                setSpinner={setSpinner}
                setShowModal={setShowPhenotypeModal}
                phenotypeResults={phenotypeResults}
                setPlantPhenotypes={setPlantPhenotypes}
                setPlantDescriptors={setPlantDescriptors}
                setPlantSummary={setPlantSummary}
                setShowSummaryModal={setShowModal}
            />
            <PlantsGroups 
                show={showPlantsGroups}
                setShow={setShowPlantsGroups}
                searchResults={ searchResults }
            />
            <NotesModal 
                show={showNotesModal}
                setShow={setShowNotesModal}
                currentPlant={currentPlant}
                setSpinner={setSpinner}
            />
            <ClonesModal 
                show={showClonesModal}
                setShow={setShowClonesModal}
                clones={clones}
                setSpinner={setSpinner}
                setPlantPhenotypes={setPlantPhenotypes}
                setPlantDescriptors={setPlantDescriptors}
                setPlantSummary={setPlantSummary}
                setShowSummaryModal={setShowModal}
            />
        </div>
        </>
    );
};

export default PlantSearch;

