import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "underscore";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import GroupService from "../services/groups.service";
import GroupList from "./GroupList";
import GroupTable from "./GroupTable";
import PhenotypeModal from "./PhenotypeModal";
import PhenotypeTable from "./PhenotypeTable";
import SummaryModal from './SummaryModal';
import CreateGroupModal from "./CreateGroupModal";
import AddRemoveModal from "./AddRemoveModal";
import MergeModal from "./MergeModal";

// Material UI
import DownloadIcon from '@mui/icons-material/Download';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import IsoIcon from '@mui/icons-material/Iso';
import CallMergeIcon from '@mui/icons-material/CallMerge';

import { Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import SearchPhenotype from "./SearchPhenotype";
import almondImage from '../img/almond.png';


// XLSX
import * as XLSX from 'xlsx/xlsx.mjs';

const Groups = () => {
    let [groups, setGroups] = useState([]);
    let [groupResults, setGroupResults] = useState([]);
    let [spinner, setSpinner] = useState(false);
    let [currentGroup, setCurrentGroup] = useState({});
    let [showModal, setShowModal] = useState(false);
    let [showCreateModal, setShowCreateModal] = useState(false);
    let [showAddRemoveModal, setShowAddRemoveModal] = useState(false);
    let [showMergeModal, setShowMergeModal] = useState(false);
    let [phenotypes, setPhenotypes] = useState([]);
    let [selection, setSelection] = useState("");
    let [option, setOption] = useState("");
    let [phenotypeResults, setPhenotypeResults] = useState([]);
    let [showPhenotypes, setShowPhenotypes] = useState(false);
    
    // Summary Modal
    let [showSummaryModal, setShowSummaryModal] = useState(false);
    let [plantSummary, setPlantSummary] = useState([]);
    let [plantPhenotypes, setPlantPhenotypes] = useState([]);
    let [plantDescriptors, setPlantDescriptors] = useState([]);
    let [maternalCrosses, setMaternalCrosses] = useState([]);
    let [paternalCrosses, setPaternalCrosses] = useState([]);

    const [key, setKey] = useState(0);  // Key to force re-render


    let clearGroups = () => {
        setGroupResults([]);
        setSpinner(false);
        setSelection("");
    }

    let downloadGroup = () => {
        setSpinner(true)
        console.log("download group results for group: ", currentGroup.Name );

        let results = [];
        _.each(groupResults, function (value) {
            // console.log("value: ", value );
            var obj = {
                idPlant: value.idPlant,
                PlantCode: value.PlantCode,
                AlternatePlantID: value.externalID,
                'Verinomics Code': value.PlantCode,
                'Company Code': value.externalID
            };
            results.push(obj);
        });
        console.log("search results: ", results);

        /* generate a worksheet */
        var ws = XLSX.utils.json_to_sheet(results);

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Verinomics");

        /* write workbook and force a download */
        XLSX.writeFile(wb, currentGroup.Name+".xlsx");
        // Loading
        setTimeout(function () {
            setSpinner(false)
        }, 500);
    }

    let downloadPhenotypes = () => {
        console.log("download..");
        setSpinner(true);
        var ids = _.pluck(groupResults, 'idPlant');
        var obj = {
            idCompany: 1,
            company: "Burchell",
            ids: ids.join(",")
        };
        console.log("obj: ", obj);
        // Send Request
        GroupService.getAvailablePhenotypes(obj).then(
            (response) => {
                // Success
                console.log("pheno response: ", response);
                console.log("found phenotypes...");

                var phenos = [];
                _.each(response.result.Phenotypes, function (value) {
                    var obj = {
                        value: value,
                        isChecked: false
                    }
                    phenos.push(obj);
                });
                phenos.push({ value: "Check All", isChecked: false });

                setPhenotypes(phenos);
                setShowModal(true);
                setSpinner(false);
            },
            (error) => {
                // Error
                console.log("something went wrong..");
                setSpinner(false);
            }
        )
    }

    /** Dropdown */
    const handleChange = (event) => {
        // console.log("event: ", event.target.value );
        setSelection(event.target.value)
        switch (event.target.value) {
            case 'select':
                setSpinner(true);
                console.log("select");
                clearGroups();
                setTimeout(function () {
                    setSelection("");
                }, 500);
                break;
            case 'add':
                console.log("add");
                setTimeout(function () {
                    setSelection("");
                }, 500);
                break;
            case 'download':
                console.log("download");
                downloadGroup();
                setTimeout(function () {
                    setSelection("");
                }, 500);
                break;
            case 'phenotype':
                console.log("phenotype");
                downloadPhenotypes();
                setTimeout(function () {
                    setSelection("");
                }, 500);
                break;
            default:
                console.log("something went wrong..");
                clearGroups();
        }

        // Reset the option to allow reselecting the same item
        setTimeout(() => {
            setOption('');
            setKey(prevKey => prevKey + 1);  // Increment key to force re-render
        }, 50);  // Small delay to ensure UI shows selection briefly
        
    };

    const handleOptions = (event) => {
        let value = event.target.value;
        console.log("value: ", value);
        setOption(value);
        switch (value) {
            case 'create':
                console.log("create..")
                setShowCreateModal (true );
                break;
            case 'manage':
                console.log("manage..");
                setShowAddRemoveModal( true );
                break
            case 'merge':
                console.log("merge...");
                setShowMergeModal( true );
                break;
            default:
                console.log(`Sorry, we are out of ${value}.`);
        }

    }

    useEffect(() => {
        // Get Groups
        GroupService.getPlantGroups().then(
            (response) => {
                // console.log("groups response: ", response);
                setGroups(response.data.result);
            },
            (error) => {
                console.log("error: ", error);
            }
        );
        // User Response
        UserService.getUserBoard().then(
            (response) => {
                // console.log("user response: ", response);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("error: ", _content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );

    }, []);

    const { user: currentUser } = useSelector((state) => state.auth);
    // console.log("current user: ", currentUser );

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="container">
            <header className="jumbotron">
                <h3> Groups </h3>
                <p>Create groups of plants and manage those groups efficiently.</p>
            </header>

            {spinner &&
                <div className="almond-spinner">
                    <img src={almondImage} alt="Almond Spinner" />
                </div>
            }

            <div className="row">
                <div className="col-md-12">

                    {groupResults.length === 0 ?
                        (
                           
                            <div className="card">
                                <div className="card-header">
                                    <div className="float-right" style={{ minWidth: 140, maxWidth: 350 }}>
                                        <Form.Group controlId="group-options" key={key}>
                                            <DropdownButton
                                                as={ButtonGroup}
                                                id="group-options"
                                                title={
                                                    <>
                                                        <SettingsIcon style={{ marginRight: "5px" }} />
                                                        <span>Options</span>
                                                    </>
                                                }
                                                onSelect={(eventKey) => handleOptions({ target: { value: eventKey } })}
                                                variant="outline-secondary"
                                                sx={{padding: "5px"}}
                                            >
                                                <Dropdown.Item eventKey="" style={{ color: "black" }}>
                                                    <em style={{ color: "gray" }}>Select Option</em>
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="create" style={{ color: "black" }}>
                                                    <CreateNewFolderIcon style={{ marginRight: "5px" }} /> Create New Group
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="manage" style={{ color: "black" }}>
                                                    <IsoIcon style={{ marginRight: "5px" }} /> Add/Remove from Group
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="merge" style={{ color: "black" }}>
                                                    <CallMergeIcon style={{ marginRight: "5px" }} /> Join/Merge Groups
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </Form.Group>
                                    </div>
                                    {/* <Box className="float-right" sx={{ minWidth: 140, maxWidth: 350 }}>
                                        <FormControl fullWidth size="small" key={key}>
                                            <InputLabel id="group-options"> <SettingsIcon /> options </InputLabel>
                                            <Select
                                                labelId="group-options"
                                                id="group-options"
                                                value={option}
                                                onChange={handleOptions}
                                            >
                                                <MenuItem value="">
                                                    <em style={{ color: "gray" }}>Select Option</em>
                                                </MenuItem>
                                                <MenuItem value={'create'}> <CreateNewFolderIcon style={{ marginRight: "5px" }} /> Create New Group</MenuItem>
                                                <MenuItem value={'manage'}><IsoIcon style={{ marginRight: "5px" }} /> Add/Remove from Group</MenuItem>
                                                <MenuItem value={'merge'}><CallMergeIcon style={{ marginRight: "5px" }} /> Join/Merge Groups </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box> */}
                                    <h5 className="card-title">Available Groups</h5>
                                </div>
                                <div className="card-body" >
                                    <GroupList
                                        groups={groups}
                                        setGroupResults={setGroupResults}
                                        setSpinner={setSpinner}
                                        setCurrentGroup={setCurrentGroup}
                                        setMaternalCrosses={setMaternalCrosses}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="card" style={{ marginBottom: "50px" }}>
                                    <div className="card-header">

                                        <Box className="float-right" sx={{ minWidth: 140, maxWidth: 350 }}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="group-options"> <SettingsIcon /> settings </InputLabel>
                                                <Select
                                                    labelId="group-options"
                                                    id="group-options"
                                                    value={selection}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <em style={{ color: "gray" }}>Select Option</em>
                                                    </MenuItem>
                                                    <MenuItem value={'selct'}> <FolderOpenIcon style={{ marginRight: "5px" }} /> Select New Group</MenuItem>
                                                    {/* <MenuItem value={'add'}><AddIcon style={{marginRight: "5px"}} /> Add Plants into Group</MenuItem> */}
                                                    <MenuItem value={'download'}><DownloadIcon style={{ marginRight: "5px" }} /> Download Group</MenuItem>
                                                    <MenuItem value={'phenotype'}><SearchIcon style={{ marginRight: "5px" }} /> Find Phenotypes</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <h5 className="card-title"> {currentGroup.Name} - {groupResults.length}</h5>
                                    </div>
                                    <div className="card-body">
                                        <GroupTable
                                            groupResults={groupResults}
                                            setGroupResults={setGroupResults}
                                            currentGroup={currentGroup}
                                            setSpinner={setSpinner}
                                            setMaternalCrosses={setMaternalCrosses}
                                            setPaternalCrosses={setPaternalCrosses}
                                        />
                                    </div>
                                </div>

                            </>
                        )}

                    <PhenotypeModal
                        show={showModal}
                        setShow={setShowModal}
                        phenotypes={phenotypes}
                        setPhenotypes={setPhenotypes}
                        setSpinner={setSpinner}
                        setSelection={setSelection}
                        setPhenotypeResults={setPhenotypeResults}
                        setShowPhenotypes={setShowPhenotypes}
                        searchResults={groupResults}
                    />

                    <PhenotypeTable
                        setSpinner={setSpinner}
                        show={showPhenotypes}
                        setShow={setShowPhenotypes}
                        setShowModal={setShowModal}
                        phenotypeResults={phenotypeResults}
                        setPlantPhenotypes={setPlantPhenotypes}
                        setPlantDescriptors={setPlantDescriptors}
                        setPlantSummary={setPlantSummary}
                        setShowSummaryModal={setShowSummaryModal}
                        setMaternalCrosses={setMaternalCrosses}
                        setPaternalCrosses={setPaternalCrosses}
                    />

                    <SummaryModal
                        show={showSummaryModal}
                        setShow={setShowSummaryModal}
                        setSpinner={setSpinner}
                        plantSummary={plantSummary}
                        plantPhenotypes={plantPhenotypes}
                        plantDescriptors={plantDescriptors}
                        maternalCrosses={maternalCrosses}
                        paternalCrosses={paternalCrosses}
                    />

                    <CreateGroupModal
                        show={showCreateModal}
                        setShow={setShowCreateModal}
                        setGroups={setGroups}
                    />

                    <AddRemoveModal 
                        show={showAddRemoveModal}
                        setShow={setShowAddRemoveModal}
                        groups={groups}
                        setSpinner={setSpinner}
                        currentUser={currentUser}
                    />

                    <MergeModal 
                        show={showMergeModal}
                        setShow={setShowMergeModal}
                        groups={groups}
                        setGroups={setGroups}
                    />

                </div>
            </div>
        </div>
    );
};

export default Groups;

