import React, { useState } from "react";
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
// import { Pagination } from '@mui/material';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Pagination, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import GroupsService from '../services/groups.service';

function GroupList(props) {
    let {
        groups,
        setCurrentGroup,
        setGroupResults,
        setSpinner,
    } = props;
    // console.log("groups: ", groups );

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const pageCount = Math.ceil(groups.length / itemsPerPage); // Calculate total number of pages

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = groups.slice(indexOfFirstItem, indexOfLastItem);
    const [error, setError ] = useState(false);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    let handleClick = ( group ) => {
        console.log("group: ", group );
        setCurrentGroup( group );
        setSpinner(true);
        // return;
        // Send Request 
        GroupsService.searchGroups( group ).then(
            (response) => {
                console.log("search group response: ", response );
                if( response.status === 0 ) {
                    setGroupResults( response.result );
                    setSpinner(false)
                } else {
                    console.log('error: ', response.message );
                    setError( true );
                    setTimeout(function() {
                        setError(false);
                    },3000);
                    setGroupResults([]);
                    setSpinner(false)
                }
            }, 
            (error) => {
                console.log('error: ', error );
                setError( true );
                setTimeout(function() {
                    setError(false);
                },3000);
                setGroupResults([]);
                setSpinner(false)
            }
        )
    }

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1); // Reset to first page when items per page changes
    };

  return (
    <>
        {error && (
              <Alert
                  severity="error" // Use the 'error' severity for red coloring akin to the Tailwind setup
                  action={
                      <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={()=> { setError(false)} }
                      >
                          <CloseIcon fontSize="inherit" />
                      </IconButton>
                  }
                  sx={{
                      mb: 2, // Margin bottom for spacing, equivalent to Tailwind's classes
                      border: 1, // Add border
                      borderColor: 'error.main', // Use the theme's error color for the border
                      backgroundColor: '#fdecea', // A light red background similar to bg-red-100
                      color: '#991b1b', // Dark red text color similar to text-red-700
                  }}
              >
                  <strong>Oops!</strong> No plants found in that group.
              </Alert>
        )}

        <Box sx={{ width: '100%', bgcolor: 'grey' }}>
            <List>
                {currentItems.map((item, i) => (
                    <ListItem disablePadding key={i} onClick={() => { handleClick(item) }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <FolderOpenIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.Name} secondary={item.Description} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
        <FormControl className="float-right" sx={{ minWidth: 120 }}>
                <InputLabel id="items-per-page-label">Items Per Page</InputLabel>
                <Select
                    labelId="items-per-page-label"
                    id="items-per-page-select"
                    value={itemsPerPage}
                    label="Groups Per Page"
                    onChange={handleItemsPerPageChange}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                </Select>
            </FormControl>
        <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
            showFirstButton
            showLastButton
            sx={{ mt: 2 }} // Styling for margin top
        />
       
    </>
  );
}


export default GroupList