import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Form, Alert } from 'react-bootstrap';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import GroupService from "../services/groups.service";

const CreateGroupModal = (props) => {
    // console.log("props: ", props);
    let {
        show,
        setShow,
        setGroups,
    } = props;
    const [groupName, setGroupName] = useState('');  // State to store the group name
    const [groupDescription, setGroupDescription] = useState('');  // State to store the group description
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleCreateGroup = (event) => {
        event.preventDefault();  // Prevent the form from submitting traditionally
        let obj = {
            name: groupName,
            description: groupDescription
        };
        console.log("obj: ", obj  );
        // Send Request
        GroupService.createGroup( obj ).then(
            (response) => {
                console.log("create response: ", response );
                setMessage('Group created successfully!');
                setIsError(false);
                setTimeout(() => {
                    setMessage('');
                },3000);
                // Get Groups
                GroupService.getPlantGroups().then(
                    (response) => {
                        console.log("groups response: ", response);
                        setGroups(response.data.result);
                    },
                    (error) => {
                        console.log("error: ", error);
                    }
                );
            }, 
            ( error ) => {
                console.log('error: ', error );
                setMessage('Failed to create group. Please try again.');
                setIsError(true);
                setTimeout(() => {
                    setMessage('');
                    setIsError(false);
                },3000);
            }
        )
    };

    return (
        <>
            <Modal show={show} 
                    onHide={handleClose} 
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
            >
                <Modal.Header>
                    <Modal.Title>Create Group</Modal.Title>
                    <CloseIcon className="float-right" onClick={handleClose} style={{ cursor: "pointer"}}/>
                </Modal.Header>
                <Modal.Body>
              
                    <Form>
                        <Form.Group controlId="formGroupName">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter group name"
                                value={groupName}
                                onChange={e => setGroupName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGroupDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter a brief description of the group"
                                value={groupDescription}
                                onChange={e => setGroupDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Form>

                    {message && (
                        <Alert variant={isError ? "danger" : "success"} style={{ marginTop: "10px", marginBottom: "15px" }}>
                            {message}
                        </Alert>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={ handleCreateGroup }>
                        <AddCircleOutlineIcon style={{marginRight: "5px"}}/>
                        Create Group
                    </Button>
                    <Button variant="outlined" onClick={handleClose} style={{ marginLeft: "5px"}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateGroupModal;







