import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, HeatmapLayer, Marker } from '@react-google-maps/api';
import { Button, ButtonGroup } from '@mui/material';
import ChartService from "../services/charts.service";

const Heatmap = (props) => {
    let {
        setShowModal,
        setSelectedLocation,
    } = props;
    const [blossomData, setBlossomData] = useState([]);
    const [days, setDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState('all');
    const [selectedPoint, setSelectedPoint] = useState(null);

    const handleButtonClick = (day) => {
        setSelectedDay(day);
        handleDayClick(day);
    };

    const mapContainerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: 37.7755,
        lng: -120.7535
    };

    useEffect(() => {
        // Get Plant Locations
        ChartService.getAllBlossomCounts({}).then(
            (response) => {
                console.log("blossom success: ", response);
                let data = formatBlossomData(response.result);
                setBlossomData(data);
                setDays(getUniqueDays(data));
            },
            (error) => {
                console.log("location error: ", error);
            }
        )
    }, []);

    const formatBlossomData = (data) => {
        return data.map((item) => ({
            location: new window.google.maps.LatLng(
                parseFloat(item.Latitude),
                parseFloat(item.Longitude)
            ),
            weight: item.Blossom_Count,
            date: formatDate(new Date(item.TimeStamp)), // Assuming TimeStamp is the field with the timestamp
            metadata: item
        }));
    };

    const formatDate = (date) => {
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const getUniqueDays = (data) => {
        const uniqueDays = [...new Set(data.map(item => item.date))];
        return uniqueDays;
    };

    const filterDataByDay = (day) => {
        if (day === 'all') {
            return blossomData;
        }
        return blossomData.filter(item => item.date === day);
    };

    const handleDayClick = (day) => {
        console.log("day: ", day);
        setSelectedDay(day);
    };

    const handleMapClick = (event) => {
        const clickedLatLng = event.latLng;
        let closestPoint = null;
        let closestDistance = Infinity;

        blossomData.forEach(dataPoint => {
            const distance = getDistance(clickedLatLng, dataPoint.location);
            if (distance < closestDistance) {
                closestDistance = distance;
                closestPoint = dataPoint;
            }
        });

        console.log("closestPoint: ", closestPoint);
        if (closestPoint) {
            setSelectedPoint(closestPoint);
            // Show Modal
            setSelectedLocation(closestPoint);
            setShowModal(true);

        } else {
            setSelectedPoint(null);
        }
    };

    const getDistance = (latLng1, latLng2) => {
        const lat1 = latLng1.lat();
        const lng1 = latLng1.lng();
        const lat2 = latLng2.lat();
        const lng2 = latLng2.lng();

        return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lng2 - lng1, 2));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                    className={`mr-1 mb-1 ${selectedDay === 'all' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('all')}
                >
                    All
                </Button>
                {days.map((day, index) => (
                    <Button
                        className={`mr-1 mb-1 ${selectedDay === day ? 'selected' : ''}`}
                        key={index}
                        onClick={() => handleButtonClick(day)}
                    >
                        {day}
                    </Button>
                ))}
            </ButtonGroup>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={19}
                mapTypeId="satellite"
                onClick={handleMapClick}
            >
                <HeatmapLayer
                    data={filterDataByDay(selectedDay)}
                    dissipating={true}
                    gradient={[
                        'rgba(0, 255, 255, 0)',
                        'rgba(0, 255, 255, 1)',
                        'rgba(0, 191, 255, 1)',
                        'rgba(0, 127, 255, 1)',
                        'rgba(0, 63, 255, 1)',
                        'rgba(0, 0, 255, 1)',
                        'rgba(0, 0, 223, 1)',
                        'rgba(0, 0, 191, 1)',
                        'rgba(0, 0, 159, 1)',
                        'rgba(0, 0, 127, 1)',
                        'rgba(63, 0, 91, 1)',
                        'rgba(127, 0, 63, 1)',
                        'rgba(191, 0, 31, 1)',
                        'rgba(255, 0, 0, 1)',
                    ]}
                    maxIntensity={10}
                    opacity={0.6}
                    radius={20}
                />
                {selectedPoint && (
                    <Marker position={selectedPoint.location} />
                )}
            </GoogleMap>
        </div>
    );
};

export default Heatmap;
