import React, { useState, useEffect, useRef } from "react";
import { TextField, Autocomplete, IconButton, Button, Alert, Card, Paper, Box, CardHeader, CardContent, CardActions, FormControl, InputLabel, Select,  Typography, Grid, InputAdornment, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { Form, FormLabel, FormGroup } from 'react-bootstrap';


import SearchIcon from '@mui/icons-material/Search';
import DatePicker from "./DatePicker"; // Assuming this is a suitable date picker component
import _ from 'underscore';
import { DateTime } from "luxon";

import GroupService from "../services/groups.service";
import PlantsService from "../services/plants.service";

function SearchPhenotype(props) {
    let {
        setSpinner,
        setLoading,
        setSearchResults,
    } = props;
    const [phenotypes, setPhenotypes] = useState([]);
    const [currentPhenotype, setCurrentPhenotype] = useState(null);
    const [valueHtml, setValueHtml] = useState(null);
    const [searchParams, setSearchParams] = useState({});
    const [successMessage, setSuccessMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState(false);
    let [startDate, setStartDate] = useState({ date: new Date() });
    let [endDate, setEndDate] = useState({ date: new Date() });
    const autoC = useRef(null);

    useEffect(() => {
        GroupService.getPhenotypes().then(
            (response) => {
                console.log("phenos response: ", response);
                /** Tidy Search Phenos */
                var arr = [];
                _.each(response.data.result, function (value) {
                    switch (value.MeasurementType) {
                        case "Categorical":
                            arr.push(value);
                            break;
                        case "Boolean":
                            arr.push(value);
                            break;
                        case "Genotype":
                            arr.push(value);
                            break;
                        case "Integer":
                            arr.push(value);
                            break;
                        case "Float":
                            arr.push(value);
                            break;
                        case "Percent":
                            arr.push(value);
                            break;
                        case "TimeStamp":
                            arr.push(value);
                            break;
                        default:
                            // console.warn("something went wrong.");
                            return;
                    }
                });

                var sorted = _.sortBy(arr, "PhenotypeName");
                // console.log('sorted: ', sorted );
                setPhenotypes(sorted);
            },
            (error) => {
                console.log("error: ", error);
            }
        )
    }, []);

    function handleStartDate(event) {
        console.log("event: ", event);
        let d = new Date(event);
        console.log("d: ", d);
        setStartDate({ date: d });
        startDate.date = d;
    }

    function handleEndDate(event) {
        console.log("event: ", event);
        let d = new Date(event);
        console.log("d: ", d);
        setEndDate({ date: d });
        endDate.date = d;
    }

    const handleSearchOptionChange = async (event, value) => {
        if (!value) return;
        console.log("value: ", value);
        setLoading(true);
        try {
            const response = await GroupService.getPhenotypeSearchType({
                Type: value.PhenotypeName,
                company: "Burchell"
            });
            const category = response.result[0].Category;
            const options = response.result[0].Search || [];
            console.log("category: ", category, "options: ", options);
            setCurrentPhenotype(value);
            buildValueHtml(category, options);
            setLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
        }
    };

    const buildValueHtml = (category, options) => {
        console.log("options:", options );
        let content;
        switch (category) {
            case 'Categorical':
            case 'Genotype':
                // Ensure options are strings and not empty
                const sanitizedOptions = options.filter(option => typeof option === 'string' && option.trim() !== '');
                console.log("sanitizedOptions:", sanitizedOptions); // Debugging log

                setSearchParams({ value: sanitizedOptions[0]  }); // Set the first option as default or 'All' if options are empty
                content = (
                    <Form.Group style={{ marginTop: "10px" }}>
                        <Form.Label>Categorical Value</Form.Label>
                        <Form.Control
                            as="select"
                            defaultValue={sanitizedOptions[0]}
                            onChange={(e) => setSearchParams({ value: e.target.value })}
                        >
                            {sanitizedOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                );
                break;
    
            case 'Float':
            case 'Percent':
                if (options.Min === undefined || options.Max === undefined) {
                    console.error("Error: Min or Max values are not defined in options.");
                    return;
                }
                setSearchParams({ min: options.Min, max: options.Max }); // Set default Min and Max from options
                content = (
                    <div>
                        <TextField
                            label="Min Value"
                            type="number"
                            defaultValue={options.Min}
                            onChange={(e) => setSearchParams(params => ({ ...params, min: e.target.value }))}
                            style={{ marginTop: "10px" }}
                            fullWidth
                        />
                        <TextField
                            label="Max Value"
                            type="number"
                            defaultValue={options.Max}
                            onChange={(e) => setSearchParams(params => ({ ...params, max: e.target.value }))}
                            style={{ marginTop: "10px" }}
                            fullWidth
                        />
                    </div>
                );
                break;
    
            case 'TimeStamp':
                const currentDate = new Date();
                setSearchParams({ startDate: currentDate, endDate: currentDate }); // Set default start and end dates to current date
                content = (
                    <>
                        <div style={{ marginTop: "10px" }}>
                            <DatePicker
                                date={startDate.date}
                                setDate={handleStartDate}
                                display="Start Date"
                                style={{ marginTop: "10px" }}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <DatePicker
                                date={endDate.date}
                                setDate={handleEndDate}
                                display="End Date"
                                style={{ marginTop: "10px" }}
                            />
                        </div>
                    </>
                );
                break;
    
            default:
                content = null;
        }
        
        console.log('content: ', content );
        setValueHtml(content); // Set the UI elements in the component's state for display
    };
    
    const searchTerms = () => {
        if (!currentPhenotype) {
            console.log("No phenotype selected.");
            return;
        }
        setLoading(true);

        console.log("currentPhenotype: ", currentPhenotype);
        console.log("searchParams: ", searchParams);
        // return;

        // Create a more structured search query based on the phenotype type
        let search = " -PhenotypeSearch ";
        switch (currentPhenotype.MeasurementType) {
            case 'Categorical':
                console.log("found cat: ");
                // Handle Alll
                if (currentPhenotype.PhenotypeValue === 'All') { currentPhenotype.PhenotypeValue = "FilterEmpty"; }
                // Fix Parentheses
                var phenoValue = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + phenoValue;
                break;
            case 'Genotype':
                console.log("found geno: ");
                // Handle Alll
                if (searchParams.value === 'All') {
                    searchParams.value = "FilterEmpty";
                }
                var p = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + p;
                break;
            case 'Descriptive':
                console.log("found desc: ");
                var pV = replaceParentheses(searchParams.value);
                search = search + currentPhenotype.PhenotypeName + ":" + pV;
                break;
            case 'Float':
                console.log("found float: ");
                var minMax = searchParams.min + ":" + searchParams.max;
                search = search + currentPhenotype.PhenotypeName + ":" + minMax;
                break;
            case 'Percent':
                console.log("found percent: ");
                var m = searchParams.min + ":" + searchParams.max;
                search = search + currentPhenotype.PhenotypeName + ":" + m;
                break;
            case 'TimeStamp':
                console.log("found time stamp: ");
                // console.log("value: ", value );
                var ob = {
                    StartDate: DateTime.fromJSDate(startDate.date).toLocaleString(),
                    EndDate: DateTime.fromJSDate(endDate.date).toLocaleString()
                };
                search = search + currentPhenotype.PhenotypeName + ":" + "DM:" + ob.StartDate + ":" + ob.EndDate;
                break;
            default:
                console.warn("didn't find measruement type.");
        }

        let obj = {
            idCompany: 1,
            company: "Burchell",
            search: search
        };

        console.log("Search object: ", obj); // Debug output to see what we are sending
        setSearchResults([]);
        // setLoading(false);
        // return;
        PlantsService.pedigreeSearchTerms(obj).then(
            response => {
                console.log("Search response:", response);
                setValueHtml( null );
            },
            error => {
                console.error("Search error:", error);
                setLoading(false);
            }
        );
    };

    return (
        <Card>
            <CardHeader title="Search Phenotypes" />
            <CardContent>
                <Box sx={{ mt: 2 }}>
                    <Autocomplete
                        disablePortal={false} // Change this to false to enable portal behavior
                        ref={autoC}
                        options={phenotypes}
                        getOptionLabel={(option) => option.PhenotypeName || ''}
                        onChange={handleSearchOptionChange}
                        renderInput={(params) => <TextField {...params} label="Phenotypes" variant="outlined" fullWidth />}
                    />
                    {valueHtml}
                    {successMessage && <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>}
                    {warningMessage && <Alert severity="warning" sx={{ mt: 2 }}>{warningMessage}</Alert>}
                </Box>
            </CardContent>
            <CardActions>
                <Button className="float-right" 
                        variant="contained" 
                        startIcon={<SearchIcon />} 
                        onClick={searchTerms} 
                        color="primary">
                    Search
                </Button>
            </CardActions>
        </Card>
    );
}

export default SearchPhenotype;


function replaceParentheses(val) {
    var string = val.toString();
    var substring = "(";
    var test = string.indexOf(substring) !== -1;
    if (test) {
        console.log("got here!!!!!")
        // Found Parentheses replace them! 
        val = val.replace("(", "OPENPARENTHESIS");
        val = val.replace(")", "CLOSEPARENTHESIS");
        return val;
    } else {
        return val;
    }
}
